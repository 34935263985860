import React, {useState} from 'react';
import AdminVideoGenres from './AdminVideoGenres';
import AdminTags from './AdminTags';
import AdminGenres from './AdminGenres';
import AdminMoods from './AdminMoods';
import { useDispatch, useSelector } from 'react-redux';
import SwitchOn from '../adminIcons/SwitchOn.svg'
import SwitchOff from '../adminIcons/SwitchOff.svg'
import BackIcon from '../adminIcons/BackIcon.svg'
import Uploads from './Uploads';
import PlusIcon from '../adminIcons/PlusIcon.svg'
import Modal from '../../app-main-content/Modal'
import AddTrack from './AddTrack';
import { addVideo } from '../../actions/videoActions';
import { useNavigate } from 'react-router-dom';


const AddVideo = (props) => {

    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [youtubeLink, setYoutubeLink] = useState('')
    const [genres, setGenres] = useState([])
    const [moods, setMoods] = useState([])
    const [videoGenres, setVideoGenres] = useState([])
    const [tags, setTags] = useState([])
    const [videoOff, setVideoOff] = useState(0)
    const [videoUrl, setVideoUrl] = useState()
    const [imageFile, setImageFile] = useState()
    const [addActive, setAddActive] = useState(false)
    const [addedTracks, setAddedTracks] = useState([])
    const [videoFile, setVideoFile] = useState()

    const navigate = useNavigate()

    const dispatch = useDispatch()

    const { uploadedVideo } = useSelector(state=>state.adminVideos)

    function toTimeString(seconds) {
        let inTime = (new Date(seconds * 1000)).toUTCString().match(/(\d\d:\d\d:\d\d)/)[0];
        if(seconds < 3600){
            inTime = inTime.slice(3, 8)
        }
        return (inTime)
      }



    const get_members = (data) =>{

        let members = '' 
        
        if (data.artists && data.artists.length){
            members = data.artists
        }

        if (data.composers && data.composers.length){
            let c = data.composers.split(',')
            for (let i in c){
                if (!members.includes(c[i])){
                    members += ', '+ c[i]
                }
            }    
        }
        return members
    } 

    const saveHandler = () => {
        console.log(uploadedVideo)
        if (!title.length){
            alert('Please, Provide Title')
        } else if (!genres.length){
            alert('Please, Provide Genre')
        } else if(!moods.length){
            alert('Please, Provide Mood')
        } else if(!videoGenres.length){
            alert('Please, Provide Video genre')
        } else if(!tags.length){
            alert('Please, Provide Tags')
        } else if(!addedTracks.length){
            alert('Please, Add Tracks')
        } else if (!uploadedVideo.file_original_path_linux){
            alert('Please, Wait Video Upload')
        } else {
            let videoInfo = {}
            videoInfo.title = title
            videoInfo.description = description
            videoInfo.yt_link = youtubeLink
            videoInfo.file_original_path_linux = uploadedVideo.file_original_path_linux
            videoInfo.duration = uploadedVideo.duration
            videoInfo.genres = genres.map(genre=>genre.id)
            videoInfo.moods = moods.map(mood=>mood.id)
            videoInfo.videoGenres = videoGenres.map(videoGenre=>videoGenre.id)
            videoInfo.tags = tags.map(tag=>tag.id)
            videoInfo.tracks = addedTracks.map(track=>({track_id : track.track_id, album_id : track.album_id, position: track.position, start_time: track.start_time, end_time: track.end_time}))
            videoInfo.status = videoOff
            dispatch(addVideo(videoInfo, imageFile))
            // setVideoFile('')
            // props.setChanges(false)
            // navigate('/adminVideos')
        }
    }


    const clickAddTrackHandler = () => {
        if (videoUrl) {
            setAddActive(true)
            props.setChanges(true)
        } else {
            alert('Please, Upload The Video First')
        }
    }

    const backHandler = () => {
        if(props.changes){
            var answer = window.confirm("You may loose the changes you made");
            if(answer){
                props.setChanges(false)
                navigate(-1)
            }
        } else {
            navigate(-1)
        }
    }


    return (
        <div className = "col-10 p-4" style={{marginLeft:"15vw", minHeight:"100vh"}}>
            <div className='d-flex align-items-center pb-4'>
                <img 
                    src = {BackIcon} 
                    role = 'button'
                    onClick={backHandler}
                    alt = ""/>
                <div className = "fw-bold fs-7 ps-4 text-secondary" 
                    role='button' 
                >VIDEOS</div>
            </div>
            <div className = "w-100 d-flex pb-5">
                <div className = "col-7 bg-success me-3 rounded p-3 pb-5">
                    <div className = "text-secondary fw-bold pb-2">Title*</div>
                    <input
                        type="text"
                        className="col-12 rounded fs-5 border-0 bg-warning text-white py-2 search-input ps-3"
                        value = {title}
                        onChange={(e)=>{setTitle(e.target.value); props.setChanges(true)}}
                    />
                    <div className = "text-secondary fw-bold pb-2 pt-4">Description</div>
                    <textarea
                        type="text"
                        className="col-12 rounded fs-5 border-0 bg-warning text-white py-2 search-input addToplaylist-list-wrapper ps-3"
                        style={{height:"10rem", resize:"none"}}
                        value = {description}
                        onChange={(e)=>{setDescription(e.target.value); props.setChanges(true)}}
                    />
                    <div className = "text-secondary fw-bold pb-2">Youtube Link</div>
                        <input
                            type="text"
                            className="col-12 rounded fs-5 border-0 bg-warning text-white py-2 search-input ps-3"
                            value = {youtubeLink}
                            onChange={(e)=>{setYoutubeLink(e.target.value);props.setChanges(true)}}
                        />
                    <AdminGenres
                        genres = {genres}
                        setGenres = {setGenres}
                        changes = {props.changes}
                        setChanges = {props.setChanges}
                    />
                    <AdminMoods
                        moods = {moods}
                        setMoods = {setMoods}
                        changes = {props.changes}
                        setChanges = {props.setChanges}
                    />
                    <AdminVideoGenres
                        videoGenres = {videoGenres}
                        setVideoGenres = {setVideoGenres}
                        changes = {props.changes}
                        setChanges = {props.setChanges}
                    />
                    <AdminTags
                        tags = {tags}
                        setTags = {setTags}
                        changes = {props.changes}
                        setChanges = {props.setChanges}
                    />
                    <div className='w-100 d-flex justify-content-between pb-2 pt-4'>
                        <div className = "text-secondary fw-bold">Tracks</div>
                        <div 
                            className = "text-info text-decoration-underline"
                            role = "button"
                            onClick={()=>setAddedTracks([])}
                            >Clear</div>
                    </div>
                    <div className = "bg-warning w-100 rounded addToplaylist-list-wrapper" style = {{maxHeight:"500px"}}>
                        {
                            addedTracks && addedTracks.length ? 
                            <div>
                                <div className = " w-100 d-flex bg-warning border-bottom border-success p-2">
                                    <div className = "text-secondary fw-bold text-wrap"
                                        style={{width:"35%"}}
                                    >Track</div>
                                    <div className = "text-secondary fw-bold text-wrap"
                                        style={{width:"30%"}}
                                    >Album Title</div>
                                    <div className = "text-secondary fw-bold text-wrap text-center"
                                        style={{width:"18%"}}
                                    >Genre</div>
                                    <div className = "text-secondary fw-bold text-wrap"
                                        style={{width:"17%"}}
                                    >Duration</div>
                                </div>
                                {
                                addedTracks.map((track, index)=>(
                                    <div key = {index} className=' w-100 d-flex bg-warning border-bottom border-success align-items-center p-2'>
                                        <div style={{width:"35%"}}>
                                            <div className='text-white px-2 text'
                                            >{track.track_title}</div>
                                            <div className='text-secondary px-2'
                                            >{get_members(track)}</div>
                                        </div>
                                        <div style={{width:"30%"}}>
                                            <div className='text-white text-wrap whitespace-nowrap px-2'
                                            >{track.album_title}</div>
                                            <div className='text-secondary px-2 fw-bold'
                                            >{track.catalogue_num}</div>
                                        </div>
                                        <div className='text-white text-wrap px-2 text-center'
                                            style={{width:"18%"}}
                                        >{track.genre}</div>
                                        <div className='text-white text-wrap px-2'
                                            style={{width:"17%"}}
                                        >{toTimeString(track.start_time) + '-' + toTimeString(track.end_time)}</div>
                                    </div>
                            ))                             
                            }</div> : ""
                        }
                        <div className='w-100 d-flex justify-content-end bg-warning border-bottom border-success d-flex align-items-center p-2'>
                            <div className='bg-secondary p-2 rounded-pill d-flex align-items-center' role='button'>
                                <img src = {PlusIcon} alt=""/>
                                <div 
                                    className='text-white pe-3 ps-1'
                                    role = "button"
                                    onClick={()=>clickAddTrackHandler()}
                                    >Add New Track</div>
                                </div>
                        </div>
                    </div>
                    <Modal 
                        trigger = {addActive}
                        setTrigger = {setAddActive}
                        top = {0}
                        right = {0}
                        content = {
                            <AddTrack
                                setIsActive = {setAddActive}
                                width = {'100vw'}
                                height = {'100dvh'}
                                setAddedTracks = {setAddedTracks}
                                addedTracks = {addedTracks}
                                videoUrl = {videoUrl}
                            /> 
                        }
                    /> 
                    <div className = "py-5" role = "button">
                    {
                        videoOff === 0 ? 
                        <div className = "d-flex align-items-center">
                            <img src = {SwitchOff} alt = "" onClick={()=>{setVideoOff(1);props.setChanges(true)}}/>
                            <div className = "text-info ms-4">Access OFF</div>
                        </div>
                            : 
                            <div className = "d-flex align-items-center">
                            <img src = {SwitchOn} alt = "" onClick={()=>{setVideoOff(0); props.setChanges(true)}}/>
                            <div className = "text-info ms-4">Access ON</div>
                        </div>
                    }
                    </div>
                    <div className = "w-100 d-flex justify-content-end">
                    {
                        uploadedVideo && uploadedVideo.file_original_path_linux ? 
                        <div className = "text-white px-5 py-2 bg-secondary rounded-pill"
                            role = 'button'
                            onClick={()=>saveHandler()}
                        >Save</div> :
                        <div className = "text-white px-5 py-2 rounded-pill"
                            style={{background:"#808080"}}
                            role = 'button'
                            onClick={()=>saveHandler()}
                        >Save</div> 
                    }
                    </div>
                </div>
                <Uploads
                    videoUrl = {videoUrl}
                    setVideoUrl = {setVideoUrl}
                    imageFile = {imageFile}
                    setImageFile = {setImageFile}
                    setChanges = {props.setChanges}
                    videoFile = {videoFile}
                    setVideoFile = {setVideoFile}
                />
            </div>
        </div>
    );
};

export default AddVideo;