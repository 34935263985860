import React, {useState, useEffect, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddVideoFilterComponent from './AddVideoFilterComponent';
import { getTagsForAdmin } from '../../actions/AdminFilterActions';
import useWindowDimentions from '../../app-main-content/useWindowDimentions';
import AddTag from './AddTag';
import Modal from '../../app-main-content/Modal'


const AdminTags = (props) => {

    const searchAdminTagRef = useRef()

    const dispatch = useDispatch()
  
    const {adminTagsLoading, adminTags} = useSelector(state => state.adminTags)

    const [tagActive, setTagActive] = useState(false)

    const [tagValue, setTagValue] = useState("")

    const [right, setRight] = useState(0)

    const [top, setTop] = useState(0)

    const [addActive, setAddActive] = useState(false)

    const [page, setPage] = useState(1)

    const {width, height} = useWindowDimentions()

    const handleScroll = event => {
        const {scrollTop, clientHeight, scrollHeight} = event.currentTarget;
        let h
        h = Math.round(scrollHeight - scrollTop)
        if(h === clientHeight || h+1 === clientHeight || h-1 === clientHeight){
          setPage(prev => prev + 1)
        }
      }

    useEffect(()=>{
        setPage(1)
        const identifer = setTimeout(()=>{
            if (tagValue){
                dispatch(getTagsForAdmin(tagValue, 1, 0))
            } else {
                dispatch(getTagsForAdmin(" ", 1, 0))
            }
        }, 500)  

        return () => { clearTimeout(identifer) }

    },[tagValue])

    useEffect(()=>{
        if (tagValue){
            dispatch(getTagsForAdmin(tagValue, page, 1))
        } else {
            dispatch(getTagsForAdmin(" ", page, 1))
        }
    }, [page])

    useEffect(()=>{
        document.addEventListener("mousedown", (event) => {
            if (searchAdminTagRef == null || searchAdminTagRef.current == null || !searchAdminTagRef.current.contains(event.target)){
            setTagActive(false)
            }
        })
    }) 

    const chooseHandler = (tag) => {
        if(!props.tags.includes(tag)){
            props.setTags(prev=>[...prev, tag])
            props.setChanges(true)
        }
        setTagActive(false)
        setTagValue("")
    }

    const addClickHandler = (e) => {
        setRight(width-e.clientX+5)
        setTop(e.clientY)
        setAddActive(true)
    }

    return(
        <>
            <div className = "text-secondary fw-bold pb-2 pt-4 w-100">Tags*</div>
            <div className='d-flex w-100 justify-content-between align-items-end'>
                <AddVideoFilterComponent
                    data = {adminTags}
                    setValue = {setTagValue}
                    setActive = {setTagActive}
                    value = {tagValue}
                    isActive = {tagActive}
                    chooseHandler = {chooseHandler}
                    componentRef = {searchAdminTagRef}
                    handleScroll = {handleScroll}
                    loading = {adminTagsLoading}
                    chosenData = {props.tags}
                    setChosenData = {props.setTags}
                    width = "90%"
                />
                <div className="py-2 px-1 bg-secondary rounded text-success text-nowrap"
                    style={{fontWeight:600}}
                    role="button"
                    onClick={(e)=>addClickHandler(e)}
                >add new</div>
            </div>
            <Modal 
                trigger = {addActive}
                setTrigger = {setAddActive}
                top = {top}
                right = {right}
                content = {
                    <AddTag
                        setIsActive = {setAddActive}
                    /> 
                }
            /> 
        </>
    )

};

export default AdminTags;