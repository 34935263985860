import {
    ADD_PLAYLIST_REQUEST,
    ADD_PLAYLIST_SUCCESS,
    ADD_PLAYLIST_FAIL,

    ADD_VIDEO_TO_PLAYLIST_REQUEST,
    ADD_VIDEO_TO_PLAYLIST_SUCCESS,
    ADD_VIDEO_TO_PLAYLIST_FAIL,

    USER_PLAYLISTS_REQUEST,
    USER_PLAYLISTS_SUCCESS,
    USER_PLAYLISTS_FAIL,

    PLAYLIST_VIDEOS_REQUEST,
    PLAYLIST_VIDEOS_SUCCESS,
    PLAYLIST_VIDEOS_FAIL,

    CHECK_VIDEO_IN_PLAYLIST_REQUEST,
    CHECK_VIDEO_IN_PLAYLIST_SUCCESS,
    CHECK_VIDEO_IN_PLAYLIST_FAIL,

    DELETE_VIDEO_FROM_PLAYLIST_REQUEST,
    DELETE_VIDEO_FROM_PLAYLIST_SUCCESS,
    DELETE_VIDEO_FROM_PLAYLIST_FAIL,

    DOWNLOAD_PLAYLIST_REQUEST,
    DOWNLOAD_PLAYLIST_SUCCESS,
    DOWNLOAD_PLAYLIST_FAIL,

    DELETE_PLAYLIST_REQUEST,
    DELETE_PLAYLIST_SUCCESS,
    DELETE_PLAYLIST_FAIL,

    ARRANGE_VIDEOS_REQUEST,
    ARRANGE_VIDEOS_SUCCESS,
    ARRANGE_VIDEOS_FAIL, 

    ADD_FULL_PLAYLIST_REQUEST,
    ADD_FULL_PLAYLIST_SUCCESS,
    ADD_FULL_PLAYLIST_FAIL, 

    TEMPORARY_PLAYLIST_REQUEST,
    TEMPORARY_PLAYLIST_SUCCESS,
    TEMPORARY_PLAYLIST_FAIL,

    PLAYLIST_GROUPS_REQUEST,
    PLAYLIST_GROUPS_SUCCESS,
    PLAYLIST_GROUPS_FAIL,

    GROUP_PLAYLISTS_REQUEST,
    GROUP_PLAYLISTS_SUCCESS,
    GROUP_PLAYLISTS_FAIL,

    ALL_PLAYLISTS_REQUEST,
    ALL_PLAYLISTS_SUCCESS,
    ALL_PLAYLISTS_FAIL,
} from '../constants/PlaylistConstants'


export const playlistGroupsReducer = (state = { playlistGroups:[] }, action) => {
  switch (action.type){
    case PLAYLIST_GROUPS_REQUEST:
      return { loading: true }

    case PLAYLIST_GROUPS_SUCCESS:
      return { 
        loading: false, 
        playlistGroups: action.payload }

    case PLAYLIST_GROUPS_FAIL:
      return { loading: false, error: action.payload }

    default:
      return state
  }
}

export const groupPlaylistsReducer = (state = { groupPlaylists:[] }, action) => {
  switch (action.type){
    case GROUP_PLAYLISTS_REQUEST:
      return { ...state, loading: true }

    case GROUP_PLAYLISTS_SUCCESS:
      return { 
        ...state,
        loading: false, 
        groupPlaylists: action.payload,
        group_id: action.group_id
      }

    case GROUP_PLAYLISTS_FAIL:
      return { error: action.payload }

    default:
      return state
  }
}


export const allPlaylistsReducer = (state = { allPlaylists:[] }, action) => {
  switch (action.type){
    case ALL_PLAYLISTS_REQUEST:
      return { loading: true, allPlaylists:[] }

    case ALL_PLAYLISTS_SUCCESS:
      return { 
        loading: false, 
        ...state,
        allPlaylists: action.payload 
      }

    case ALL_PLAYLISTS_FAIL:
      return { loading: false, error: action.payload }

    default:
      return state
  }
}


const initialState = {
  userPlaylists: [],
  added_playlist: null,
  playlistVideos: [],
  video_ids_in_playlist: [],
  deleted_video:null,
  active_pl_id: null,
  playlist_user_id: null,
  active_playlist_title: null
}

export const userPlaylistsReducer = (state = initialState, action) => {

  let added_playlist_id = null

  switch (action.type){
    case USER_PLAYLISTS_REQUEST:
      return { 
        ...state,
        userPlaylistloading: true 
      }

    case USER_PLAYLISTS_SUCCESS:
      return { 
        ...state,
        userPlaylistloading: false, 
        userPlaylists: action.payload,
      }

      case ADD_PLAYLIST_SUCCESS:
        return {
          ...state,
          loading: false,
          added_playlist: action.payload,
          userPlaylists : [...state.userPlaylists, action.payload]
        }
  
      case ADD_PLAYLIST_FAIL:
        return { 
          ...state,
          loading: false, 
          error: action.payload }


      case ADD_FULL_PLAYLIST_SUCCESS:
        return {
          ...state,
          loading: false,
          added_playlist: action.payload,
          userPlaylists : [...state.userPlaylists, action.payload]
        }
  
      case ADD_FULL_PLAYLIST_FAIL:
        return { 
          ...state,
          loading: false, 
          error: action.payload }

    case USER_PLAYLISTS_FAIL:
      return { 
        ...state,
        userPlaylistloading: false, 
        error: action.payload 
      }

    case PLAYLIST_VIDEOS_REQUEST:
      return { 
        ...state,
        loading: true
      }

    case PLAYLIST_VIDEOS_SUCCESS:
      return { 
        ...state,
        loading: false, 
        playlistVideos: action.videos,
        active_pl_id: action.active_pl_id,
        playlist_user_id: action.playlist_user_id,
        active_playlist_title: action.active_pl_title
        }

    case TEMPORARY_PLAYLIST_REQUEST:
      return { 
        ...state,
        loadingTemp: true
      }

    case TEMPORARY_PLAYLIST_SUCCESS:
      return { 
        ...state,
        loadingTemp: false, 
        active_pl_id: action.active_pl_id,
        playlist_user_id: action.playlist_user_id,
        active_playlist_title: action.active_pl_title,
        group_id: action.group_id
        }

    case TEMPORARY_PLAYLIST_FAIL:
      return { 
        ...state,
        loadingTemp: false, 
        error: action.payload
        }

    case ARRANGE_VIDEOS_REQUEST:
      return { 
        ...state
      }

    case ARRANGE_VIDEOS_SUCCESS:
      return {
        playlistVideos: state.playlistVideos,
        ...state
     }

    case ARRANGE_VIDEOS_FAIL:
      return { 
        ...state, 
        error: action.payload }

    case CHECK_VIDEO_IN_PLAYLIST_REQUEST:
      return { 
        ...state
      }

    case CHECK_VIDEO_IN_PLAYLIST_SUCCESS:
      return { 
        ...state,
        loadingIds: false, 
        video_ids_in_playlist: action.payload 
      }

    case CHECK_VIDEO_IN_PLAYLIST_FAIL:
      return { 
        ...state,
        loadingIds: false, 
        error: action.payload }

    case ADD_VIDEO_TO_PLAYLIST_REQUEST:
      return { 
        ...state,
        loadingAddVideo: true
      }

    case ADD_VIDEO_TO_PLAYLIST_SUCCESS:
      added_playlist_id = action.playlist_id 
      const pl = state.userPlaylists.find(pl => pl.id === added_playlist_id)
      pl.duration += action.payload.reduce((n, {duration}) => n + duration, 0)
      if(!pl.video_id){
        pl.video_id = action.payload[0].id
      }
      if (added_playlist_id === state.active_pl_id){
        return { 
          ...state,
          loadingAddVideo: false, 
          playlistVideos: [...state.playlistVideos, ...action.payload],
          video_ids_in_playlist: [...state.video_ids_in_playlist, added_playlist_id]
        }
      } else {
        return {
          ...state,
          loadingAddVideo: false, 
          video_ids_in_playlist: [...state.video_ids_in_playlist, added_playlist_id]
        }
      }

    case ADD_VIDEO_TO_PLAYLIST_FAIL:
      return { 
        ...state,
        loadingAddVideo: false, 
        error: action.payload
        }

    case PLAYLIST_VIDEOS_FAIL:
      return { 
        ...state,
        loading: false, 
        error: action.payload
        }

    case DELETE_VIDEO_FROM_PLAYLIST_REQUEST:
      return { 
        ...state,
        loadingDeleteVideo: true
      }

    case DELETE_VIDEO_FROM_PLAYLIST_SUCCESS:
      const pld = state.userPlaylists.find(pl => pl.id === action.deleted_playlist_id)
      pld.duration -= action.video_duration
      if(pld.video_id === action.deleted_video_id){
        pld.video_id = action.payload.id
      }
      if (action.deleted_playlist_id === state.active_pl_id){
        return { 
          ...state,
          loadingDeleteVideo: false, 
          playlistVideos: state.playlistVideos.filter(video => video.id!== action.deleted_video_id),
          video_ids_in_playlist: state.video_ids_in_playlist.filter(id => id!==action.deleted_playlist_id)
        }
      } else {
        return {
          ...state,
          loadingDeleteVideo: false, 
          video_ids_in_playlist: state.video_ids_in_playlist.filter(id => id!== action.deleted_playlist_id)
        }
      }

    case DELETE_PLAYLIST_REQUEST:

      return { ...state}

    case DELETE_PLAYLIST_SUCCESS:
      let playlist_id = action.deleted_id
        return {
          ...state,
          userPlaylists : state.userPlaylists.filter(pl => pl.id!=playlist_id),
          playlistVideos: action.videos,
          active_pl_id: action.active_pl_id,
          playlist_user_id: action.playlist_user_id,
          active_playlist_title: action.active_pl_title
         }

    case DELETE_PLAYLIST_FAIL:
      return { 
        ...state,
        deletePlaylistError: action.payload 
      }

    case DELETE_VIDEO_FROM_PLAYLIST_FAIL:
      return { 
        ...state,
        loadingDeleteVideo: false, 
        error: action.payload
        }
  

    default:
      return state
  }
}

export const downloadReducer = (state = { }, action) => {
  switch (action.type){
    case DOWNLOAD_PLAYLIST_REQUEST:
      return { loading: true}

    case DOWNLOAD_PLAYLIST_SUCCESS:
      return { 
        loading: false, 
        file:  action.payload }

    case DOWNLOAD_PLAYLIST_FAIL:
        console.log(action.payload)
      return { 
        loading: false, 
        error: action.payload 
    }

    default:
      return state
  }
}