import axios from 'axios'
import { Store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import Notification from '../app-main-content/playlistAdds/Notification'


import {
  GET_GROUPS_FOR_ADMIN_REQUEST,
  GET_GROUPS_FOR_ADMIN_SUCCESS,
  GET_GROUPS_FOR_ADMIN_FAIL,

  ADD_GROUP_REQUEST,
  ADD_GROUP_SUCCESS,
  ADD_GROUP_FAIL,

  EDIT_GROUP_REQUEST,
  EDIT_GROUP_SUCCESS,
  EDIT_GROUP_FAIL,

  DELETE_GROUP_REQUEST,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAIL,

  ARRANGE_GROUPS_REQUEST,
  ARRANGE_GROUPS_SUCCESS,
  ARRANGE_GROUPS_FAIL,

  CHANGE_GROUP_STATUS_REQUEST,
  CHANGE_GROUP_STATUS_SUCCESS,
  CHANGE_GROUP_STATUS_FAIL,
  
  GET_GROUP_INFO_REQUEST,
  GET_GROUP_INFO_SUCCESS,
  GET_GROUP_INFO_FAIL,

  DELETE_GROUP_PLAYLIST_REQUEST,
  DELETE_GROUP_PLAYLIST_SUCCESS,
  DELETE_GROUP_PLAYLIST_FAIL,

  } from '../constants/GroupsConstants'


  export const getGroupsForAdmin = () => async (dispatch, getState) => {

    const config = {
      headers:{
          'Content-type':'application/json',
          'Authorization': `JWT ${localStorage.getItem('access')}`,
          'Accept': 'application/json'
        }
      }

    try {
      dispatch({
        type: GET_GROUPS_FOR_ADMIN_REQUEST
      })
  
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/cuviewTV/get_groups_for_admin/`, config
        )
  
      dispatch({
        type: GET_GROUPS_FOR_ADMIN_SUCCESS,
        payload: data
      })
  
    } catch(error) {
      dispatch({
        type: GET_GROUPS_FOR_ADMIN_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
      })
    }
  }


export const addGroup = (group_title) => async (dispatch, getState) => {

    const config = {
      headers:{
          'Content-type':'application/json',
          'Authorization': `JWT ${localStorage.getItem('access')}`,
          'Accept': 'application/json'
        }
      }

    try {
      dispatch({
        type: ADD_GROUP_REQUEST
      })
  
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/cuviewTV/add_group/`, {'title': group_title}, config
        )


      dispatch({
        type: ADD_GROUP_SUCCESS,
        payload: res.data
      })

      Store.addNotification({
        content: <Notification message = {"Group has been added"}/>,
        type: 'custom',
        container: "bottom-left",
        insert: "top",
        dismiss: {
            duration: 3000,
            pauseOnHover: true
        }
        })
  
    } catch(error) {
      dispatch({
        type: ADD_GROUP_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
      })
    }
  }

export const editGroup = (group_id, title, status, positions) => async (dispatch, getState) => {

    const config = {
      headers:{
          'Content-type':'application/json',
          'Authorization': `JWT ${localStorage.getItem('access')}`,
          'Accept': 'application/json'
        }
      }

    try {
      dispatch({
        type: EDIT_GROUP_REQUEST
      })
  
        const res = await axios.put(
          `${process.env.REACT_APP_API_URL}/cuviewTV/edit_group/${group_id}/`, {'title': title, 'status':status, 'positions':positions}, config
        )
  
      dispatch({
        type: EDIT_GROUP_SUCCESS,
        payload: res.data
      })
  
    } catch(error) {
      dispatch({
        type: EDIT_GROUP_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
      })
    }
  }

export const deleteGroup = (group_id, position) => async (dispatch, getState) => {

    const config = {
      headers:{
          'Content-type':'application/json',
          'Authorization': `JWT ${localStorage.getItem('access')}`,
          'Accept': 'application/json'
        }
      }

    try {
      dispatch({
        type: DELETE_GROUP_REQUEST
      })
  
        const res = await axios.delete(
          `${process.env.REACT_APP_API_URL}/cuviewTV/delete_group/${group_id}/`, config
        )
  
      dispatch({
        type: DELETE_GROUP_SUCCESS,
        payload: res.data,
        group_id: group_id,
        position: position
      })

      Store.addNotification({
        content: <Notification message = {"Group has been deleted"}/>,
        type: 'custom',
        container: "bottom-left",
        insert: "top",
        dismiss: {
            duration: 3000,
            pauseOnHover: true
        }
        })
  
    } catch(error) {
      dispatch({
        type: DELETE_GROUP_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
      })
    }
  }


export const changeGroupStatus = (group_id, status) => async (dispatch, getState) => {

    const config = {
      headers:{
          'Content-type':'application/json',
          'Authorization': `JWT ${localStorage.getItem('access')}`,
          'Accept': 'application/json'
        }
      }

    try {
      dispatch({
        type: CHANGE_GROUP_STATUS_REQUEST
      })
  
        const res = await axios.put(
          `${process.env.REACT_APP_API_URL}/cuviewTV/change_group_status/${group_id}/${status}`, config
        )
  
      dispatch({
        type: CHANGE_GROUP_STATUS_SUCCESS,
        payload: res.data
      })
  
    } catch(error) {
      dispatch({
        type: CHANGE_GROUP_STATUS_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
      })
    }
  }


  export const arrangeGroups = (pos1, pos2) => async (dispatch, getState) => {

    const config = {
      headers:{
          'Content-type':'application/json',
          'Authorization': `JWT ${localStorage.getItem('access')}`,
          'Accept': 'application/json'
        }
      }
  
  
    try {
      dispatch({
        type: ARRANGE_GROUPS_REQUEST
      })
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/cuviewTV/arrange_groups/${pos1}/${pos2}/`, config
        )
  
      dispatch({
        type: ARRANGE_GROUPS_SUCCESS,
        payload: data
      })
  
    } catch(error) {
      dispatch({
        type: ARRANGE_GROUPS_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
      })
    }
  }


  export const getGroupInfo = (group_id) => async (dispatch, getState) => {

    const config = {
      headers:{
          'Content-type':'application/json',
          'Authorization': `JWT ${localStorage.getItem('access')}`,
          'Accept': 'application/json'
        }
      }

    try {
      dispatch({
        type: GET_GROUP_INFO_REQUEST
      })
  
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/cuviewTV/get_group_info/${group_id}/`, config
        )
  
      dispatch({
        type: GET_GROUP_INFO_SUCCESS,
        payload: data
      })
  
    } catch(error) {
      dispatch({
        type: GET_GROUP_INFO_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
      })
    }
  }


  
export const deleteGroupPlaylist = (group_id, playlist_id, position) => async (dispatch, getState) => {

  const config = {
    headers:{
        'Content-type':'application/json',
        'Authorization': `JWT ${localStorage.getItem('access')}`,
        'Accept': 'application/json'
      }
    }

  try {
    dispatch({
      type: DELETE_GROUP_PLAYLIST_REQUEST
    })

      const res = await axios.delete(
        `${process.env.REACT_APP_API_URL}/cuviewTV/delete_playlist_from_group/${group_id}/${playlist_id}/`, config
      )

    dispatch({
      type: DELETE_GROUP_PLAYLIST_SUCCESS,
      payload: res.data,
      playlist_id: playlist_id,
      position: position
    })

    Store.addNotification({
      content: <Notification message = {"Playlist Has Been Deleted from Group"}/>,
      type: 'custom',
      container: "bottom-left",
      insert: "top",
      dismiss: {
          duration: 3000,
          pauseOnHover: true
      }
      })

  } catch(error) {
    dispatch({
      type: DELETE_GROUP_PLAYLIST_FAIL,
      payload: error.response && error.response.data.message
      ? error.response.data.message
      : error.message
    })
  }
}
