import React, { useEffect, useState  } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listPlaylistVideos, arrangeVideos } from '../../actions/PlaylistActions'
import SidePlaylistComponent from './SidePlaylistComponent'
import {
    DndContext, 
    closestCorners,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core"
import {
    arrayMove,
    SortableContext,
    verticalListSortingStrategy,
    sortableKeyboardCoordinates,
  } from "@dnd-kit/sortable";


const SidePlaylist = (props) => {

    const playlistVideosList = useSelector(state => state.userPlaylists)

    const dispatch = useDispatch()

    const [items, setItems] = useState()

    let playlist_id = parseInt(props.searchParams.get('p')) ? parseInt(props.searchParams.get('p')) : localStorage.getItem('playlist_id') ? localStorage.getItem('playlist_id') : 54

    const { playlistVideos, playlist_user_id, active_pl_id } = playlistVideosList

    
    useEffect(()=>{
        dispatch(listPlaylistVideos(playlist_id))
    },[dispatch])

    useEffect(()=>{
      if(playlistVideos && playlistVideos.length){
        setItems(playlistVideos)
      }

    },[playlistVideos])

    useEffect(()=>{
      if(active_pl_id){
        props.setSearchParams({ ...Object.fromEntries([...props.searchParams]), p:active_pl_id})
      }
    },[active_pl_id])

    const sensors = useSensors(
        useSensor(MouseSensor, {
          // Require the mouse to move by 10 pixels before activating
          activationConstraint: {
            distance: 10,
          },
        }),
        useSensor(TouchSensor, {
          // Press delay of 250ms, with tolerance of 5px of movement
          activationConstraint: {
            delay: 250,
            tolerance: 5,
          },
        }),
        useSensor(KeyboardSensor, {
          coordinateGetter: sortableKeyboardCoordinates,
        })
      );

    // const handleDragEnd = (event) => {
    //     const {active, over} = event
    //     let index

    //     if(active.id === over.id ) {
    //         return
    //     } 

    //     let video1 = playlistVideos.find(video => video.id === active.id)
    //     let video2 = playlistVideos.find(video => video.id === over.id)
    //     index = video1.position > video2.position ? 1 : 2
    //     dispatch(arrangeVideos(playlist_id, video1.position, video2.position, index))
    // }

    const handleDragEnd = (event) => {
      const {active, over} = event;
      if (active.id !== over.id) {
        setItems((items) => {
          const oldIndex = items.map(item=>item.position).indexOf(active.id);
          const newIndex = items.map(item=>item.position).indexOf(over.id);
          if (active.id>over.id){
              items.forEach(item => {
                  if(item.position < active.id && item.position >= over.id){
                      item.position += 1
                  } else if (item.position === active.id){
                      item.position = over.id
                  }
              });
          } else if (active.id<over.id){
              items.forEach(item => {
                  if(item.position > active.id && item.position <= over.id){
                      item.position -= 1
                  } else if (item.position === active.id){
                      item.position = over.id
                  }
              });
          } 
          return arrayMove(items, oldIndex, newIndex);
        });
        dispatch(arrangeVideos(playlist_id, active.id, over.id))
      }
  }

    return(
        <>
            <DndContext 
                sensors={sensors}
                collisionDetection={closestCorners}
                onDragEnd={handleDragEnd}
                >
                {
                    items && items.length  ?
                    <div  className = "d-flex flex-column position-relative side_slide"
                        style={{gap:"0.5rem"}}
                    >
                          <SortableContext items = {items.map(video=>video.position)} strategy={verticalListSortingStrategy}>
                            {
                                items.map((video, index) =>
                                <div key={index}>
                                  <SidePlaylistComponent
                                      video = {video}
                                      setVideo = {props.setVideo}
                                      playlist_user_id = {playlist_user_id}
                                      playlist_id = {playlist_id}
                                      searchParams = {props.searchParams}
                                      setSearchParams = {props.setSearchParams}
                                  />
                                </div>)
                            }
                        </SortableContext>
                    </div> : ""
                }
                </DndContext>
        </>
    )

}


export default SidePlaylist;