import React, {useEffect, useRef, useState} from 'react';
import search from '../adminIcons/search.svg'
import addIcon from '../adminIcons/add.svg'
import addDarkIcon from '../adminIcons/add_dark.svg'
import filterIcon from '../adminIcons/filterIcon.svg'
import AdminFilter from './AdminFilter';
import { executeAdminSearch, getVideosForAdmin, getVideosLength, getVideosLengthForSearch, getVideosOff, getVideosOffLength} from '../../actions/videoActions';
import { useDispatch, useSelector } from 'react-redux';
import { getSearchSuggestions } from '../../actions/filterActions';
import { useNavigate } from 'react-router-dom'
import Modal from '../../app-main-content/Modal'
import useWindowDimentions from '../../app-main-content/useWindowDimentions';
import AddSeveralVideos from './AddSeveralVideos';
import SelectIcon from '../adminIcons/Select.svg'
import SelectedIcon from '../adminIcons/Selected.svg'

const VideosHeader = (props) => {

    let adminSearchRef = useRef()

    const dispatch = useDispatch()

    const navigate = useNavigate()
  
    const [searchActive, setSearchActive] = useState(false)
    const [filterActive, setFilterActive] = useState(false)
    const [val, setVal] = useState("")

    const [right, setRight] = useState(0)

    const [top, setTop] = useState(0)

    const {width, height} = useWindowDimentions()

    const [addActive, setAddActive] = useState(false)

    const [offVideos, setOffVideos] = useState(0)

    const addClickHandler = (e) => {
        setRight(width-e.clientX+5)
        setTop(e.clientY)
        setAddActive(true)
    }
  

    const executeSearch = (value) => {
        if(value && value.length){
            props.setPage(1)
            dispatch(getVideosLengthForSearch(value))
            dispatch(executeAdminSearch(value, 1, 3))
        }
        setSearchActive(false)
  }
  
  
    useEffect(()=>{
      const identifer = setTimeout(()=>{
          if (props.searchValue){
            dispatch(getSearchSuggestions(props.searchValue))
            setSearchActive(true)
          } else {
            setSearchActive(false)
          }
      }, 500)  
  
      return () => { clearTimeout(identifer) }
  
    },[val])
  
  
    const {searchSuggestions, loadingSuggestions} = useSelector(state=>state.searchSuggestions)
    
  
    // chooses a value from suggested results from currentSearch results
    const chooseHandler = (value) => {
      props.setSearchValue(value.title)
      executeSearch(value.title)
    }

    const handleKeyDown = (e) => {
        if(e.code === "Enter"){
          executeSearch(props.searchValue)
        }
      }

    useEffect(()=>{
        document.addEventListener("mousedown", (event) => {
          if (adminSearchRef == null || adminSearchRef.current == null || !adminSearchRef.current.contains(event.target)){
            setSearchActive(false)
          }
        })
      })

    
    useEffect(()=>{
        if(searchSuggestions && !searchSuggestions.length && !loadingSuggestions){
            setFilterActive(true)
        }
    },[searchSuggestions])

    return (
        <>
            <div className='w-100 d-flex justify-content-between align-items-center'>
                <div className = 'd-flex'>
                    <div className = "fw-bold fs-7 text-info" 
                        role='button' 
                        onClick={()=>{
                            props.setPage(1);
                            setOffVideos(0)
                            dispatch(getVideosLength());
                            dispatch(getVideosForAdmin(1,1))}}>VIDEOS</div>
                    {
                        offVideos === 0 ? 
                        <div className = "d-flex align-items-center ps-4" role = 'button'>
                            <img src = {SelectIcon} alt = "" 
                                onClick={()=>{
                                    setOffVideos(1); 
                                    props.setPage(1);
                                    dispatch(getVideosOffLength());
                                    dispatch(getVideosOff(1,4))}}/>
                            <div className = "text-info ms-3">Inactive Videos</div>
                        </div>
                            : 
                            <div className = "d-flex align-items-center ps-4" role = 'button'>
                            <img src = {SelectedIcon} alt = "" 
                                onClick={()=>{                                    
                                    setOffVideos(0); 
                                    props.setPage(1);
                                    dispatch(getVideosLength());
                                    dispatch(getVideosForAdmin(1,1))}}/>
                            <div className = "text-info ms-3">Inactive Videos</div>
                        </div>
                    }
                </div>
                <div className='d-flex justify-content-end align-items-center col-9'>
                    <div className='col-9 col-lg-10 d-flex flex-column justify-content-end align-items-end admin-search-wrapper'>
                        <div className = "col-12 px-2 col-lg-9 position-relative d-flex justify-content-end align-items-center" 
                            style={{userSelect:"none", height:"2.5rem"}} 
                            ref = {adminSearchRef}
                            >
                            <div className="w-100 h-100 w-lg-75 col-lg-10 bg-success d-flex py-1 rounded border border-secondary"
                            >
                                <input
                                    type="text"
                                    className="col-11 fs-5 border-0 bg-success text-secondary search-input ps-3"
                                    placeholder="Search by title..."
                                    onKeyDown={handleKeyDown}
                                    onClick={()=>setSearchActive(true)}
                                    onChange={(e)=>{props.setSearchValue(e.target.value); setVal(e.target.value)}}
                                    value={props.searchValue}
                                />
                                <div className='col-1 d-flex justify-content-end pe-2 bg-success'
                                    onClick={()=> {props.setPage(1); executeSearch(props.searchValue)}}
                                >
                                    <img src={search} alt="search icon" role= "button" className='p-2' />
                                </div>
                                {
                                    searchActive ? 
                                    loadingSuggestions ? <div>Loading...</div> :
                                    searchSuggestions && searchSuggestions.length ?
                                        <div className = "w-100 w-lg-75 position-absolute mt-5 p-2 bg-white shadow rounded" 
                                        style = {{color:"#333", zIndex:1000}}>
                                            {
                                            searchSuggestions.map((result) => (
                                                <div
                                                    className = "p-2 addSearchDropdown-item"
                                                    role = "button"
                                                    style={{transition:"all 0.2s"}}
                                                    key = {result.id}
                                                    onClick = {()=>chooseHandler(result)}
                                                >{result.title}</div>
                                            ))
                                            }
                                        </div> : 
                                        <div className='w-100 w-lg-75 position-absolute mt-5 p-2 bg-white shadow rounded'>No Result, please try a different query</div> :""
                                    }
                                    </div>
                        </div>
                    </div>
                    <img className = 'ps-2 pe-4' 
                        role = 'button'
                        src = {filterIcon}
                        onClick={()=>setFilterActive(!filterActive)}/>
                    <div className = 'bg-secondary rounded-pill py-2 px-3 d-flex align-items-center'
                        role='button'
                        onClick = {()=>navigate('/addVideo')}>
                        <img src = {addIcon}/>
                        <div className='text-white ps-1 text-nowrap'>Add new Video</div>
                    </div>
                    { props.videoList && props.videoList.length ?
                        <div className = 'bg-danger rounded-pill ms-2 py-2 px-3 d-flex align-items-center'
                            role='button'
                            onClick = {addClickHandler}>
                            <img src = {addDarkIcon}/>
                            <div className='text-primary ps-1 text-nowrap'>Add Selected</div>
                        </div> : ""
                    }
                </div>
            </div>
            {
                filterActive &&
                <AdminFilter
                    filterList = {props.filterList}
                    setFilterList = {props.setFilterList}
                    setPage = {props.setPage}
                />
            }

            <Modal 
                trigger = {addActive}
                setTrigger = {setAddActive}
                top = {top}
                right = {right}
                content = {
                    <AddSeveralVideos
                        setIsActive = {setAddActive}
                        videoList = {props.videoList}
                        setAddActive = {setAddActive}
                    /> 
                }
            /> 

        </>
    );
};

export default VideosHeader;