import React, {useState, useEffect, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddVideoFilterComponent from './AddVideoFilterComponent';
import { getGenresForAdmin } from '../../actions/AdminFilterActions';


const AdminGenres = (props) => {

    const searchAdminGenreRef = useRef()

    const dispatch = useDispatch()

    const [genreActive, setGenreActive] = useState(false)

    const [genreValue, setGenreValue] = useState("")


    useEffect(()=>{
        dispatch(getGenresForAdmin())   
    },[])

    const {adminGenresLoading, adminGenres} = useSelector(state => state.adminGenres)

    useEffect(()=>{
        document.addEventListener("mousedown", (event) => {
            if (searchAdminGenreRef == null || searchAdminGenreRef.current == null || !searchAdminGenreRef.current.contains(event.target)){
            setGenreActive(false)
            }
        })
    }) 

    const chooseHandler = (genre) => {
        if(!props.genres.includes(genre)){
            props.setGenres(prev=>[...prev, genre])
            props.setChanges(true)
        }
        setGenreActive(false)
        setGenreValue("")
    }

    const handleScroll = () => {

    }

    return(
        <>
            <div className = "text-secondary fw-bold pb-2 pt-4 w-100">Genres*</div>
            <div className='d-flex w-100 justify-content-between align-items-end'>
                <AddVideoFilterComponent
                    data = {adminGenres}
                    setValue = {setGenreValue}
                    setActive = {setGenreActive}
                    value = {genreValue}
                    isActive = {genreActive}
                    chooseHandler = {chooseHandler}
                    componentRef = {searchAdminGenreRef}
                    handleScroll = {handleScroll}
                    loading = {adminGenresLoading}
                    chosenData = {props.genres}
                    setChosenData = {props.setGenres}
                    width = "100%"
                />
            </div>
        </>
    )

};

export default AdminGenres;