import React from 'react'
import Slides from './Slides';

export default function SliderContent(props) {

    return (
        <div className=''
            style={{
                transform: `translateX(-${props.translate}vw)`,
                transition: `transform ease-in-out .5s`,
                height: '100%',
                width: `${props.sliderContentWidth}vw`,
                display: 'flex',
            }}
        >
            <Slides
              showTitle = {props.showTitle}
              type = {props.type}
              displayCount={props.displayCount}
              slideWrapperWidth={props.slideWrapperWidth}
              tvData={props.tvData}
              slideClickHandler={props.slideClickHandler}
               />
        </div>
    )
}
