import React, {useEffect, useState} from 'react';
import DeleteIcon from '../adminIcons/DeleteIcon.svg'
import EditIcon from '../adminIcons/EditIcon.svg'
import PlusIcon from '../adminIcons/PlusIcon.svg'
import { deletePlaylistForAdmin } from '../../actions/AdminPlaylistsActions'; 
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Modal from '../../app-main-content/Modal'
import useWindowDimentions from '../../app-main-content/useWindowDimentions';
import AddToGroup from './AddToGroup';

const PlaylistComponent = (props) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    const [right, setRight] = useState(0)

    const [top, setTop] = useState(0)

    const {width, height} = useWindowDimentions()

    const [addActive, setAddActive] = useState(false)

    const convertDate = (d) =>{
        var date = new Date(d); // parses ISO 8601
        return(date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear()+" "+date.getHours()+":"+date.getMinutes()+":"+date.getSeconds())
    }

    const deleteHandler = () => {
        var answer = window.confirm("Do you really want to delete the playlist?");
        if (answer) {
            dispatch(deletePlaylistForAdmin(props.playlist.id))
        }
    }

    const addClickHandler = (e) => {
        setRight(width-e.clientX+5)
        setTop(e.clientY)
        setAddActive(true)
    }

    return (
        <div className='w-100 bg-success d-flex align-items-center px-3 py-1 border-top border-secondary'>
            <div className = 'px-2' style = {{width:'12%'}}>
                <img
                    className = 'w-100 p-1' 
                    src = {`https://img.cugate.com/?o=CUTV_PLAYLIST&i=${props.playlist.id}&s=300&qq=${Math.random()}`}
                    style={{aspectRatio:"14/9"}}/>
            </div>
            <div className = 'text-white mx-2' style = {{width:'50%'}}>{props.playlist.title}</div>
            <div className = 'text-white mx-2' style = {{width:'29%'}}>{convertDate(props.playlist.updated)}</div>
            <div className = 'mx-2 d-flex justify-content-around' style = {{width:'8%'}}>
                <img 
                    src = {EditIcon} alt = "" 
                    role='button'
                    onClick={()=>navigate(`/editPlaylist/${props.playlist.id}`)}/>
                <img src = {PlusIcon} alt = "" 
                    role='button'
                    onClick={addClickHandler}/>
                <img 
                    src = {DeleteIcon} alt = "" 
                    role='button'
                    onClick={()=>deleteHandler()}
                />
            </div>
            <Modal 
                trigger = {addActive}
                setTrigger = {setAddActive}
                top = {top}
                right = {right}
                content = {
                    <AddToGroup
                        setIsActive = {setAddActive}
                        playlist = {props.playlist}
                        gr = {0}
                    /> 
                }
            /> 
        </div>
    );
};

export default PlaylistComponent;