import React, { useState } from 'react';
import cugate from '../images/cugate.png'
import searchBtn from '../icons/search_btn.svg'
import {useNavigate} from 'react-router-dom'
import Themes from './Themes';
import ThemesMobile from './ThemesMobile';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../actions/AuthActions';

function LandingHeader(props) {

    const navigate = useNavigate()

    const dispatch = useDispatch()

    const [profileActive, setProfileActive] = useState(false)

    const [searchValue, setSearchValue] = useState("")

    const logoutHandler = () =>{
        dispatch(logout())
        navigate('/')
      }

    return (
        <>
        <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-transparent px-2 px-lg-5" id="mainNavbar">
        <div className="container-fluid">
            <a className="navbar-brand d-none d-lg-block" href="#"><img src={cugate} alt="" /></a>
            <div className="d-flex d-lg-none justify-content-between align-items-center w-100">
                <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasNavbar" aria-expanded="false" aria-label="Toggle navigation"
                    >
                    <span className="navbar-toggler-icon" 
                        style={{width:"30px", height:"30px"}}></span>
                </button>
                <a className="navbar-brand" href="#">
                    <img src={cugate} alt="" /></a>

                <form className="form-inline search_form mobile d-flex align-items-center mx-2">
                    <div className="search-cont">
                        <input type="search" 
                        id="searchInputMobile" 
                        placeholder="Title, description" 
                        aria-label="Search"
                        value = {searchValue}
                        onChange={(e)=>setSearchValue(e.target.value)}/>
                        <img id="searchButtonSmall" src={searchBtn} 
                        onClick = {()=>{
                            localStorage.setItem("searchValue", searchValue)
                            if(props.user){
                                navigate(`/home?g=4&p=54&v=403`)
                            } else {
                                navigate('/login')
                            }

                        }}/>
                    </div>
                </form>

            </div>
            <div className="d-none d-lg-flex align-items-center">
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0" style={{display:'flex', alignItems:'flex-start'}}>
                    <li className="nav-item">
                        <div className="nav-link active" aria-current="page" 
                        style = {{cursor:'pointer'}} 
                        onClick={()=>navigate(`/home?g=4&p=54&v=403`)}>Home</div>
                    </li>
                    <Themes
                        user = {props.user}
                    />
                </ul>
                <form className="form-inline search_form d-inline-block ms-5 me-4">
                    <div className="search-cont">
                        <input type="search" 
                        placeholder="Title, description" 
                        aria-label="Search"
                        value = {searchValue}
                        onChange={(e)=>setSearchValue(e.target.value)}/>
                        <img className="search-button" id="searchButtonBig" src={searchBtn}
                            onClick = {()=>{
                                localStorage.setItem("searchValue", searchValue);
                                if(props.user){
                                    navigate(`/home?g=4&p=54&v=403`)
                                } else {
                                    navigate('/login')
                                }
                            }} />
                    </div>
                </form>
                {   props.userLoading ? <div></div> :
                    !props.user ?
                    <div className="mx-4" style={{fontWeight: "bold", color:"white", cursor:"pointer"}}
                    onClick={()=>navigate(`/login`)}> Log in</div> : 
                    <div className='w-100 position-relative'>
                        <div className="mx-4" style={{fontWeight: "bold", color:"white", cursor:"pointer"}}
                            onClick={()=>setProfileActive(!profileActive)}>{props.user.first_name}</div>
                        {
                            profileActive && 
                            <div 
                                className = "px-2 py-1 mt-2 bg-white position-absolute text-center rounded" 
                                onMouseLeave = {()=> setProfileActive(false)}>
                                <div className = "px-3 py-2 w-100 rounded color-secondary profile-menu-object" 
                                onClick = { logoutHandler } >Logout
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    </nav>
    <div className="offcanvas offcanvas-start" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
        <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasNavbarLabel"></h5>
            <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">

            <ul className="nav flex-column">
                <li className="nav-item" style = {{cursor:'pointer'}}>
                    <a className="nav-link active" aria-current="page" href="#">Home</a>
                </li>
                <li className="nav-item">
                    <div className="btn-group w-100">
                        <button className="btn text-start nav-link" type="button" >Themes</button>
                        <button type="button" className="btn dropdown-toggle dropdown-toggle-split collapsed"
                            data-bs-toggle="collapse" href="#collapseThemesOffcanvas" aria-expanded="false">
                            <span className="visually-hidden">Toggle Dropdown</span>
                        </button>
                    </div>
                    <ul className="nav flex-column ps-4 collapse" id="collapseThemesOffcanvas">
                        <ThemesMobile
                            user = {props.user}
                        />
                    </ul>
                </li>
            </ul>

            <div className="mt-4">
                <div  onClick={()=>navigate(`/login`)} className="ms-3" style={{fontWeight: 'bold', color:'white', cursor:'pointer'}}> Log in </div>
            </div>
        </div>
    </div>

</>
    );
}

export default LandingHeader;