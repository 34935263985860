import React, { useState, useRef, useEffect } from 'react'
import Dots from '../icons/Dots.svg'
import plusVideo from '../icons/plusVideo.svg'
import deleteVideo from '../icons/deleteVideo.svg'
import shareVideo from '../icons/shareVideo.svg'
import default_cover from '../images/default_cover.jpg'
import { deleteVideoFromPlaylist } from '../../actions/PlaylistActions'
import { useSelector, useDispatch } from 'react-redux'
import ShareContent from './ShareContent'
import AddVideoToPlaylistContent from '../playlistAdds/AddVideoToPlaylistContent'
import Modal from '../Modal'
import useWindowDimentions from '../useWindowDimentions'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

const SidePlaylistComponent = (props) => {

    const [menuActive, setMenuActive] = useState(false)

    const [sideShareActive, setSideShareActive] = useState(false)

    const [addsActive, setAddsActive] = useState(false)

    const [right, setRight] = useState(0)

    const [top, setTop] = useState(0)

    const {width, height} = useWindowDimentions()

    const sideComponentRef = useRef()

    const {user} = useSelector(state=>state.user)

    const [disableDnD, setDisableDnD] = useState(true);

    const { attributes, listeners, setNodeRef, transform, transition, isDragging  } = useSortable({id: props.video.position, disabled: disableDnD})

    const listenersOnState = disableDnD ?  undefined : {...listeners} 


    useEffect(()=>{
        if(user.id === props.playlist_user_id){
            setDisableDnD(false)
        }
        else {
            setDisableDnD(true)
        }
    },[props.playlist_user_id])

    const dispatch = useDispatch()

    useEffect(()=>{
        document.addEventListener("mousedown", (event) => {
          if (sideComponentRef == null || sideComponentRef.current == null || !sideComponentRef.current.contains(event.target)){
            setMenuActive(false)
          }
        });
      })

    const get_members = (data) =>{

        let members = '' 
        
        if (data.artists && data.artists.length){
            members = data.artists
        }

        if (data.composers && data.composers.length){
            let c = data.composers.split(',')
            for (let i in c){
                if (!members.includes(c[i])){
                    members += ', '+ c[i]
                }
            }    
        }
        return members
    }

    function toTimeString(seconds) {
        let inTime = (new Date(seconds * 1000)).toUTCString().match(/(\d\d:\d\d:\d\d)/)[0];
        if(seconds < 3600){
            inTime = inTime.slice(3, 8)
        }
        return (inTime)
      }

    const videoClickHandler = (v) => {
        props.setVideo(v)
        localStorage.setItem('video_id', v.id)
        props.setSearchParams({ ...Object.fromEntries([...props.searchParams]), v:v.id})
    }

    const addClickHandler = (e) => {
        setRight(width-e.clientX+5)
        setTop(e.clientY)
        setAddsActive(true)
    }

    const shareClickHandler = (e) => {
        setRight(width-e.clientX+5)
        setTop(e.clientY-20)
        setSideShareActive(true)
    }


    const style = {
        transition,
        transform: CSS.Transform.toString(transform)
    }

    return (
        <div
            className = 'col-12 d-flex position-relative flex-row pe-3 side-slide-component'
            id={props.video.position}
            ref = {setNodeRef}
            {...attributes}
            {...listenersOnState}
            style = {style}>
            <div className = 'col-4 col-lg-6 position-relative'
                role = "button"
                onClick={(e)=>{videoClickHandler(props.video)}}
            >
                <img src = {`https://img.cugate.com/?o=CUTV_VIDEO&i=${props.video.id}&s=300`}
                    className = "w-100 rounded object-fit-cover"
                    role = "button"
                    style={{aspectRatio:"178/100", minHeight:"100%"}}
                    onError = {({ currentTarget }) => {
                        currentTarget.onerror = null // prevents looping
                        currentTarget.src=default_cover
                        }}
                />
                <div className = "position-absolute text-white px-1 rounded bg-dark"
                    style={{zIndex:"1", bottom:"0.05rem", right:"0.2rem"}}>
                        {toTimeString(props.video.duration)}
                </div>
            </div>
            <div className = 'col-7 col-lg-5 d-flex flex-column ps-3 ps-lg-2 side-playlist-info'
                role = "button"
                onClick={()=>videoClickHandler(props.video)}>
                <div className = 'text-white fw-bold overflow-hidden' 
                    title={props.video.title}
                    style={{maxHeight:"3rem", textOverflow:"ellipsis"}}>{props.video.title}</div>
                <div className = 'text-info fst-italic fs-5 overflow-hidden ' 
                    title={get_members(props.video)}
                    style={{maxHeight:"2.5rem"}}>{get_members(props.video)}</div>
            </div>
            <div className = 'position-relative col-1 text-end h-100'
                ref = {sideComponentRef}
            >
                <img src = {Dots}
                    role='button'
                    onClick={()=>{setMenuActive(true)}}
                />
                {
                    menuActive && 
                    <div className='position-absolute px-2 py-2 d-flex flex-column rounded' 
                        style={{background:"#BBD8E7", zIndex:1, gap:"0.5rem", marginTop:"-2rem"}}>
                        <img 
                            src = {plusVideo}
                            onClick={(e)=>addClickHandler(e)}
                            role='button'/>
                        {
                            props.playlist_user_id === user.id ? 
                            <img 
                            src = {deleteVideo}
                            onClick={()=> {dispatch(deleteVideoFromPlaylist(props.video.id, props.playlist_id, props.video.duration)); setMenuActive(false)}}
                            role='button'/> : ""
                        }
                        <img 
                            src = {shareVideo}
                            onClick={(e)=>shareClickHandler(e)}
                            role='button'/>
                    </div>
                }
                <Modal 
                    trigger = {sideShareActive}
                    setTrigger = {setSideShareActive}
                    top = {top}
                    right = {right}
                    content = {
                        <ShareContent
                            setShareActive = {setSideShareActive}
                            video = {props.video}
                        /> 
                    }
                />        
                <Modal 
                    trigger = {addsActive}
                    setTrigger = {setAddsActive}
                    top = {top}
                    right = {right}
                    content = {
                        <AddVideoToPlaylistContent
                            setIsActive = {setAddsActive}
                            video_list = {[props.video.id]}
                            index = {1}
                            video = {props.video}
                        /> 
                    }
                />             
            </div>
        </div>
    );
}

export default SidePlaylistComponent;