import React from 'react';
import UserContent from './app-main-content/UserContent';
import AdminContent from './adminContent/AdminContent';
import AuthContent from './app-main-content/AuthContent';

const Content = (props) => {

  return (
    <>
      <UserContent/>
      <AuthContent/>
      <AdminContent/>
    </>
  );
}

export default Content;