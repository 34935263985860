import React, {useState, useEffect, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSearchSuggestions } from '../../actions/filterActions';
import { executeAdminSearch} from '../../actions/videoActions';
import { getBannerPlaylists } from '../../actions/BannerActions';

const ChooseVideo = (props) => {

    const [searchActive, setSearchActive] = useState(false)

    const [searchValue, setSearchValue] = useState("")

    let bannerSearchRef = useRef()

    const dispatch = useDispatch()

    const {searchSuggestions} = useSelector(state=>state.searchSuggestions)

    useEffect(()=>{
      const identifer = setTimeout(()=>{
          if (searchValue){
              dispatch(getSearchSuggestions(searchValue))
              setSearchActive(true)
          } else {
              dispatch(getSearchSuggestions(" "))
              setSearchActive(false)
          }
      }, 500)  
  
      return () => { clearTimeout(identifer) }
  
    },[searchValue])

    const chooseHandler = (value) => {
        dispatch(getBannerPlaylists(value.id))
        props.setVideo(value)
        props.setIndex(2)
    }
   

    return (
        <div className='d-flex flex-column admin-search-wrapper'>
            <div className = 'px-2 text-secondary fs-8 pb-3 fw-bold'>CHOOSE VIDEO</div>
            <div className = "col-12 px-2 position-relative d-flex justify-content-end align-items-center" 
                style={{userSelect:"none", height:"2.5rem"}} 
                ref = {bannerSearchRef}
                >
                <div className="w-100 h-100 w-lg-75 col-lg-10 bg-success d-flex py-1 rounded border border-secondary"
                >
                    <input
                        type="text"
                        className="col-11 fs-5 border-0 bg-success text-secondary search-input ps-3"
                        placeholder="Write or Select"
                        onChange={(e)=>{setSearchValue(e.target.value)}}
                        value={searchValue}
                    />
                    {
                        searchActive && searchSuggestions && searchSuggestions.length ?
                            <div className = "w-100 w-lg-75 position-absolute mt-5 p-2 bg-white shadow rounded side_slide" 
                            style = {{color:"#333", zIndex:1000}}>
                                {
                                searchSuggestions.map((result) => (
                                    <div
                                        className = "p-1 addSearchDropdown-item d-flex align-items-center"
                                        role = "button"
                                        style={{transition:"all 0.2s"}}
                                        key = {result.id}
                                        onClick = {()=>chooseHandler(result)}
                                    >
                                        <img 
                                            src = {`https://img.cugate.com/?o=CUTV_VIDEO&i=${result.id}&s=300`}
                                            style={{width:"4rem", height:"3rem"}}/>
                                        <div className = 'px-4'>{result.title}</div>
                                    </div>
                                ))
                                }
                            </div> : ''
                        }
                        </div>
            </div>
        </div> 
    );
};

export default ChooseVideo;