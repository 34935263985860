export const GET_GENRES_REQUEST = 'GET_GENRES_REQUEST'
export const GET_GENRES_SUCCESS = 'GET_GENRES_SUCCESS'
export const GET_GENRES_FAIL = 'GET_GENRES_FAIL'

export const GET_MOODS_REQUEST = 'GET_MOODS_REQUEST'
export const GET_MOODS_SUCCESS = 'GET_MOODS_SUCCESS'
export const GET_MOODS_FAIL = 'GET_MOODS_FAIL'

export const GET_ARTISTS_REQUEST = 'GET_ARTISTS_REQUEST'
export const GET_ARTISTS_SUCCESS = 'GET_ARTISTS_SUCCESS'
export const GET_ARTISTS_FAIL = 'GET_ARTISTS_FAIL'

export const GET_COMPOSERS_REQUEST = 'GET_COMPOSERS_REQUEST'
export const GET_COMPOSERS_SUCCESS = 'GET_COMPOSERS_SUCCESS'
export const GET_COMPOSERS_FAIL = 'GET_COMPOSERS_FAIL'

export const GET_TAGS_REQUEST = 'GET_TAGS_REQUEST'
export const GET_TAGS_SUCCESS = 'GET_TAGS_SUCCESS'
export const GET_TAGS_FAIL = 'GET_TAGS_FAIL'

export const GET_VIDEOGENRES_REQUEST = 'GET_VIDEOGENRES_REQUEST'
export const GET_VIDEOGENRES_SUCCESS = 'GET_VIDEOGENRES_SUCCESS'
export const GET_VIDEOGENRES_FAIL = 'GET_VIDEOGENRES_FAIL'

export const FILTER_REQUEST = 'FILTER_REQUEST'
export const FILTER_SUCCESS = 'FILTER_SUCCESS'
export const FILTER_FAIL = 'FILTER_FAIL'

export const SEARCH_SUGGESTIONS_REQUEST = 'SEARCH_SUGGESTIONS_REQUEST'
export const SEARCH_SUGGESTIONS_SUCCESS = 'SEARCH_SUGGESTIONS_SUCCESS'
export const SEARCH_SUGGESTIONS_FAIL = 'SEARCH_SUGGESTIONS_FAIL'

export const SEARCH_RESULTS_REQUEST = 'SEARCH_RESULTS_REQUEST'
export const SEARCH_RESULTS_SUCCESS = 'SEARCH_RESULTS_SUCCESS'
export const SEARCH_RESULTS_FAIL = 'SEARCH_RESULTS_FAIL'