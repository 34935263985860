import {
    GET_BANNER_VIDEOS_REQUEST,
    GET_BANNER_VIDEOS_SUCCESS,
    GET_BANNER_VIDEOS_FAIL,

    BANNER_PLAYLISTS_REQUEST,
    BANNER_PLAYLISTS_SUCCESS,
    BANNER_PLAYLISTS_FAIL,

    BANNER_THEMES_REQUEST,
    BANNER_THEMES_SUCCESS,
    BANNER_THEMES_FAIL, 

    ADD_BANNER_VIDEO_REQUEST,
    ADD_BANNER_VIDEO_SUCCESS,
    ADD_BANNER_VIDEO_FAIL, 

    DELETE_BANNER_VIDEO_REQUEST,
    DELETE_BANNER_VIDEO_SUCCESS,
    DELETE_BANNER_VIDEO_FAIL, 

    EDIT_BANNER_VIDEO_REQUEST,
    EDIT_BANNER_VIDEO_SUCCESS,
    EDIT_BANNER_VIDEO_FAIL, 

    ARRANGE_BANNER_VIDEOS_REQUEST,
    ARRANGE_BANNER_VIDEOS_SUCCESS,
    ARRANGE_BANNER_VIDEOS_FAIL, 
} from '../constants/BannerConstants'


const initialState = {
    bannerVideos: [],
    loadingBannerVideos: false
}

export const bannerVideosReducer = (state = initialState, action) => {
    switch (action.type){
      case GET_BANNER_VIDEOS_REQUEST:
        return { 
          ...state,
          loadingBannerVideos: true
        }
  
      case GET_BANNER_VIDEOS_SUCCESS:
        return { 
          ...state,
          loadingBannerVideos: false, 
          bannerVideos:  action.payload,
         }
  
      case GET_BANNER_VIDEOS_FAIL:
        return { 
          loadingBannerVideos: false, 
          error: action.payload,
          ...state
        }

      case ADD_BANNER_VIDEO_REQUEST:
        return { 
          ...state
        }
  
      case ADD_BANNER_VIDEO_SUCCESS:

        return { 
          ...state,
          bannerVideos: [...state.bannerVideos, action.payload],
        }
  
      case ADD_BANNER_VIDEO_FAIL:
        return { 
          loadingBannerVideos: false, 
          error: action.payload,
          ...state
        }

      case DELETE_BANNER_VIDEO_REQUEST:
        return { 
          ...state
        }
  
      case DELETE_BANNER_VIDEO_SUCCESS:
        return { 
          ...state,
          bannerVideos: state.bannerVideos.filter( video => video.id !== action.bannervideo_id),
        }
  
      case DELETE_BANNER_VIDEO_FAIL:
        return { 
          loadingBannerVideos: false, 
          error: action.payload,
          ...state
        }

      
        case EDIT_BANNER_VIDEO_REQUEST:
          return { 
            ...state
          }
    
        case EDIT_BANNER_VIDEO_SUCCESS:
          let bannervideo = state.bannerVideos.find(video => video.id === action.video_id)
          bannervideo.banner_title = action.title
          bannervideo.status = action.status
          bannervideo.id = action.video_id
          return { 
            bannerVideos: state.bannerVideos,
            ...state,
          }
    
        case EDIT_BANNER_VIDEO_FAIL:
          return { 
            loadingBannerVideos: false, 
            error: action.payload,
            ...state
          }
  
      default:
        return state
    }
  }


  export const bannerPlaylistsReducer = (state = {bannerPlaylists:[]}, action) => {
    switch (action.type){
      case BANNER_PLAYLISTS_REQUEST:
        return { loadingBannerPlaylists: true}
  
      case BANNER_PLAYLISTS_SUCCESS:
        return { loadingBannerPlaylists: false, bannerPlaylists:  action.payload }
  
      case BANNER_PLAYLISTS_FAIL:
        return { loadingBannerPlaylists: false, error: action.payload }
  
      default:
        return state
    }
  }

  export const bannerThemesReducer = (state = {bannerThemes:[]}, action) => {
    switch (action.type){
      case BANNER_THEMES_REQUEST:
        return { loadingBannerThemes: true}
  
      case BANNER_THEMES_SUCCESS:
        return { loadingBannerThemes: false, bannerThemes:  action.payload }
  
      case BANNER_THEMES_FAIL:
        return { loadingBannerThemes: false, error: action.payload }
  
      default:
        return state
    }
  }