import axios from 'axios'

import {
    GET_GENRES_REQUEST,
    GET_GENRES_SUCCESS,
    GET_GENRES_FAIL,

    GET_MOODS_REQUEST,
    GET_MOODS_SUCCESS,
    GET_MOODS_FAIL,

    GET_ARTISTS_REQUEST,
    GET_ARTISTS_SUCCESS,
    GET_ARTISTS_FAIL,

    GET_COMPOSERS_REQUEST,
    GET_COMPOSERS_SUCCESS,
    GET_COMPOSERS_FAIL,

    GET_TAGS_REQUEST,
    GET_TAGS_SUCCESS,
    GET_TAGS_FAIL,

    GET_VIDEOGENRES_REQUEST,
    GET_VIDEOGENRES_SUCCESS,
    GET_VIDEOGENRES_FAIL,

    FILTER_REQUEST,
    FILTER_SUCCESS,
    FILTER_FAIL,

    SEARCH_SUGGESTIONS_REQUEST,
    SEARCH_SUGGESTIONS_SUCCESS,
    SEARCH_SUGGESTIONS_FAIL,

    SEARCH_RESULTS_REQUEST,
    SEARCH_RESULTS_SUCCESS,
    SEARCH_RESULTS_FAIL 
} from '../constants/filterConstants'


export const getGenres = (components) => async (dispatch, getState) => {
    try {
        dispatch({
        type: GET_GENRES_REQUEST
        })
        
        const body = {data: components}

        const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/cuviewTV/get_genres_for_filter/`, body
        )

        dispatch({
        type: GET_GENRES_SUCCESS,
        payload: res.data
        })

    } catch(error) {
        dispatch({
        type: GET_GENRES_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}

export const getMoods = (components) => async (dispatch, getState) => {

    try {
        dispatch({
        type: GET_MOODS_REQUEST
        })

        const body = {data: components}

        const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/cuviewTV/get_moods_for_filter/`, body
        )

        dispatch({
        type: GET_MOODS_SUCCESS,
        payload: res.data
        })

    } catch(error) {
        dispatch({
        type: GET_MOODS_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}


export const getArtists = (keyword, components, page, index) => async (dispatch, getState) => {
    try {

        dispatch({
            type: GET_ARTISTS_REQUEST
            })

        const body = {data: components}

        const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/cuviewTV/get_artists_for_filter/${keyword}/${page}/`, body
        )

        dispatch({
        type: GET_ARTISTS_SUCCESS,
        payload: res.data,
        index: index,
        page: page
        })

    } catch(error) {
        dispatch({
        type: GET_ARTISTS_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}

export const getComposers = (keyword, components, page, index) => async (dispatch, getState) => {
    try {

        dispatch({
            type: GET_COMPOSERS_REQUEST
            })

        const body = {data: components}

        const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/cuviewTV/get_composers_for_filter/${keyword}/${page}/`, body
        )

        dispatch({
        type: GET_COMPOSERS_SUCCESS,
        payload: res.data,
        index: index,
        page: page
        })

    } catch(error) {
        dispatch({
        type: GET_COMPOSERS_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}

export const executeFilter = (components, page, index) => async (dispatch, getState) => {

    try {
        dispatch({
        type: FILTER_REQUEST
        })

        const body = {data: components}

        const res  = await axios.post(
            `${process.env.REACT_APP_API_URL}/cuviewTV/get_filter_results/${page}/`,
            body
        )

        dispatch({
        type: FILTER_SUCCESS,
        payload: res.data,
        page: page, 
        index: index
        })

    } catch(error) {
        dispatch({
        type: FILTER_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}

export const getTags = (keyword, components, page, index) => async (dispatch, getState) => {
    try {

        dispatch({
            type: GET_TAGS_REQUEST
            })

        const body = {data: components}

        const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/cuviewTV/get_tags_for_filter/${keyword}/${page}/`, body
        )

        dispatch({
        type: GET_TAGS_SUCCESS,
        payload: res.data,
        index: index,
        page: page
        })

    } catch(error) {
        dispatch({
        type: GET_TAGS_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}


export const getVideoGenres = (keyword, components, page, index) => async (dispatch, getState) => {
    try {

        dispatch({
            type: GET_VIDEOGENRES_REQUEST
            })

        const body = {data: components}

        const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/cuviewTV/get_videogenres_for_filter/${keyword}/${page}/`, body
        )

        dispatch({
        type: GET_VIDEOGENRES_SUCCESS,
        payload: res.data,
        index: index,
        page: page
        })

    } catch(error) {
        dispatch({
        type: GET_VIDEOGENRES_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}


export const getSearchSuggestions = (keyword) => async (dispatch, getState) => {
    try {

        dispatch({
            type: SEARCH_SUGGESTIONS_REQUEST
        })

        const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/cuviewTV/get_search_suggestions/${keyword}/`, {}
        )

        dispatch({
        type: SEARCH_SUGGESTIONS_SUCCESS,
        payload: res.data
        })

    } catch(error) {
        dispatch({
        type: SEARCH_SUGGESTIONS_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}


export const getSearchResults = (keyword, page, index) => async (dispatch, getState) => {
    try {
        dispatch({
        type: SEARCH_RESULTS_REQUEST
        })

        const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/cuviewTV/get_search_results/${keyword}/${page}/`,{}
        )

        dispatch({
        type: SEARCH_RESULTS_SUCCESS,
        payload: res.data,
        page: page,
        index: index
        })

    } catch(error) {
        dispatch({
        type: SEARCH_RESULTS_FAIL,
        payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
        })
    }
}