import React, {useState} from 'react';
import SearchAlbum from './SearchAlbum';
import Albums from './Albums';
import Tracks from './Tracks';
import TrackTimes from './TrackTimes';
import ExitIcon from '../adminIcons/CrossIconInfo.svg'

const AddTrack = (props) => {

    const [component, setComponent] = useState(1)

    const [albumValue, setAlbumValue] = useState("")

    const [album, setAlbum] = useState()

    const [track, setTrack] = useState()

    const exitHandler = () => {
        setAlbum()
        setComponent(1)
        setAlbumValue()
        setTrack()
        props.setIsActive(false)
    }


    return (
        <div className=" d-flex justify-content-center pb-5 side_slide"
            style={{
                backgroundColor: "rgba(0,0,0,.6)",
                width:props.width, 
                height:props.height, 
                minHeight:'100vh',
                // maxHeight:"fit-content", 
                // overflowY:"scroll",
                paddingTop:"109px"}}
        >
            <div className='bg-light col-10 rounded' 
                style={{
                    height: "fit-content", 
                    paddingTop:"48px",
                    paddingLeft:"114px", 
                    paddingRight:"114px",
                    paddingBottom:"109px"}}>
            <div className = 'd-flex justify-content-end'>
                <img 
                    src = {ExitIcon}
                    alt = ''
                    role = 'button'
                    onClick={exitHandler}/>
            </div>
            {
                component === 1 ? 
                <SearchAlbum
                    setComponent = {setComponent}
                    albumValue = {albumValue}
                    setAlbumValue = {setAlbumValue}
                    setAlbum = {setAlbum}
                /> : 
                component === 2 ? 
                <Albums
                    setComponent = {setComponent}
                    albumValue = {albumValue}
                    setAlbum = {setAlbum}
                /> : 
                component === 3 ? 
                <Tracks
                    setComponent = {setComponent}
                    album = {album}
                    setTrack = {setTrack}
                /> : 
                component === 4 ? 
                <TrackTimes
                    track = {track}
                    setAddedTracks = {props.setAddedTracks}
                    addedTracks = {props.addedTracks}
                    album = {album}
                    setIsActive = {props.setIsActive}
                    videoUrl = {props.videoUrl}
                /> : ""     
            }
            </div>
        </div>
    );
};

export default AddTrack;