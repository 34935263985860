import {
    GET_GENRES_REQUEST,
    GET_GENRES_SUCCESS,
    GET_GENRES_FAIL,

    GET_MOODS_REQUEST,
    GET_MOODS_SUCCESS,
    GET_MOODS_FAIL,

    GET_ARTISTS_REQUEST,
    GET_ARTISTS_SUCCESS,
    GET_ARTISTS_FAIL,

    GET_COMPOSERS_REQUEST,
    GET_COMPOSERS_SUCCESS,
    GET_COMPOSERS_FAIL,

    GET_TAGS_REQUEST,
    GET_TAGS_SUCCESS,
    GET_TAGS_FAIL,

    GET_VIDEOGENRES_REQUEST,
    GET_VIDEOGENRES_SUCCESS,
    GET_VIDEOGENRES_FAIL,

    FILTER_REQUEST,
    FILTER_SUCCESS,
    FILTER_FAIL,

    SEARCH_SUGGESTIONS_REQUEST,
    SEARCH_SUGGESTIONS_SUCCESS,
    SEARCH_SUGGESTIONS_FAIL,

    SEARCH_RESULTS_REQUEST,
    SEARCH_RESULTS_SUCCESS,
    SEARCH_RESULTS_FAIL,
} from '../constants/filterConstants'


export const genresReducer = (state = { genres: []}, action) => {
    switch (action.type){
      case GET_GENRES_REQUEST:
        return { loading: true, genres : []}
  
      case GET_GENRES_SUCCESS:
        return { loading: false, genres: action.payload }
  
      case GET_GENRES_FAIL:
        return { loading: false, error: action.payload }
  
      default:
        return state
    }
  }

  export const moodsReducer = (state = { moods:[]}, action) => {
    switch (action.type){
      case GET_MOODS_REQUEST:
        return { loading: true,    moods : []}
  
      case GET_MOODS_SUCCESS:
        return { loading: false, moods: action.payload }
  
      case GET_MOODS_FAIL:
        return { loading: false, error: action.payload }
  
      default:
        return state
    }
  }

  export const artistsReducer = (state = { artists: []}, action) => {
    switch (action.type){

      case GET_ARTISTS_REQUEST:
        if(action.index === 0){
          return { loading: true, artists: []}
        } else {
          return {...state}
        }
  
      case GET_ARTISTS_SUCCESS:
        if(action.index === 0 || action.page === 1){
          return { 
            loading: false, 
            artists: action.payload
          } 
        } else {
          return { 
            loading: false, 
            artists: [...state.artists, ...action.payload]
          } 
        }

  
      case GET_ARTISTS_FAIL:
        return { 
          ...state,
          loading: false, 
          error: action.payload 
        }
  
      default:
        return state
    }
  }


  export const composersReducer = (state = { composers: [] }, action) => {
    switch (action.type){

      case GET_COMPOSERS_REQUEST:
        if(action.index === 0){
          return { loading: true, composers: []}
        } else {
          return {...state}
        }
  
      case GET_COMPOSERS_SUCCESS:
        if(action.index === 0 || action.page === 1){
          return { 
            loading: false, 
            composers: action.payload
          } 
        } else {
          return { 
            loading: false, 
            composers: [...state.composers, ...action.payload]
          } 
        }
  
      case GET_COMPOSERS_FAIL:
        return { 
          ...state,
          loading: false, 
          error: action.payload }
  
      default:
        return state
    }
  }

  export const tagsReducer = (state = { tags: [] }, action) => {
    switch (action.type){

      case GET_TAGS_REQUEST:
        if(action.index === 0){
          return { loading: true, tags: []}
        } else {
          return {...state}
        }
  
      case GET_TAGS_SUCCESS:
        if(action.index === 0 || action.page === 1){
          return { 
            loading: false, 
            tags: action.payload
          } 
        } else {
          return { 
            loading: false, 
            tags: [...state.tags, ...action.payload]
          } 
        }
  
      case GET_TAGS_FAIL:
        return { 
          ...state,
          loading: false, 
          error: action.payload }
  
      default:
        return state
    }
  }


  export const videogenresReducer = (state = { videogenres: [] }, action) => {
    switch (action.type){

      case GET_VIDEOGENRES_REQUEST:
        if(action.index === 0){
          return { loading: true, videogenres: []}
        } else {
          return {...state}
        }
  
      case GET_VIDEOGENRES_SUCCESS:
        if(action.index === 0 || action.page === 1){
          return { 
            loading: false, 
            videogenres: action.payload
          } 
        } else {
          return { 
            loading: false, 
            videogenres: [...state.videogenres, ...action.payload]
          } 
        }
  
      case GET_VIDEOGENRES_FAIL:
        return { 
          ...state,
          loading: false, 
          error: action.payload }
  
      default:
        return state
    }
  }

  export const filterReducer = (state = { filterResults: [] }, action) => {
    switch (action.type){
      case FILTER_REQUEST:
        if(action.index === 0){
          return { loading: true, filterResults: []}
        } else {
          return {...state}
        }
  
      case FILTER_SUCCESS:
        if(action.index === 0 || action.page === 1){
          return { 
            loading: false, 
            filterResults: action.payload
          } 
        } else {
          return { 
            loading: false, 
            filterResults: [...state.filterResults, ...action.payload]
          } 
        }
  
      case FILTER_FAIL:
        return { 
          ...state,
          loading: false, 
          error: action.payload }
  
      default:
        return state
    }
  }

  export const searchSuggestionsReducer = (state = { loadingSuggestions: true, searchSuggestions: [] }, action) => {
    switch (action.type){

      case SEARCH_SUGGESTIONS_REQUEST:
        return { ...state }
  
      case SEARCH_SUGGESTIONS_SUCCESS:
        return { loadingSuggestions: false, searchSuggestions:  action.payload }
  
      case SEARCH_SUGGESTIONS_FAIL:
        return { error: action.payload, loadingSuggestions: false,  }
  
      default:
        return state
    }
  }


  export const searchResultsReducer = (state = { searchResults: [] }, action) => {
    switch (action.type){
      case SEARCH_RESULTS_REQUEST:
        if(action.index === 0){
          return { loading: true, searchResults: []}
        } else {
          return {...state}
        }
  
      case SEARCH_RESULTS_SUCCESS:
        if(action.index === 0 || action.page === 1){
          return { 
            loading: false, 
            searchResults: action.payload
          } 
        } else {
          return { 
            loading: false, 
            searchResults: [...state.searchResults, ...action.payload]
          } 
        }
  
      case SEARCH_RESULTS_FAIL:
        return { 
          ...state,
          loading: false, 
          error: action.payload }
  
      default:
        return state
    }
  }