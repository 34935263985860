import React, {useEffect} from 'react'
import 'react-notifications-component/dist/theme.css'
import but_ON from '../icons/but_ON.svg'
import but_OFF from '../icons/but_OFF.svg'
import { useSelector, useDispatch } from 'react-redux'
import { checkVideosInPlaylist, addVideoToPlaylist, deleteVideoFromPlaylist } from '../../actions/PlaylistActions'



const AddVideoToPlaylistList = (props) => {

  const dispatch = useDispatch()

  //finds the ids of playlists, in which the video already exists
  useEffect(()=>{
    if(props.video){
      dispatch(checkVideosInPlaylist(props.video.id))
    }
  },[dispatch])

  const userPlaylistsList = useSelector(state => state.userPlaylists)

  const {userPlaylists, video_ids_in_playlist} = userPlaylistsList

  const clickFromFilter = (playlist) =>{
    dispatch(addVideoToPlaylist(props.video_list, playlist.id))
  }

  const clickHandler = (playlist) => {
    if (video_ids_in_playlist){
      if(!video_ids_in_playlist.includes(playlist.id)){
            dispatch(addVideoToPlaylist(props.video_list, playlist.id))
          }
      else  {
            dispatch(deleteVideoFromPlaylist(props.video.id, playlist.id, props.video.duration))
          }
    }
  }

  return (
    <>
        {
            userPlaylists && userPlaylists.length ? userPlaylists.map(playlist =>(
              <div
                className = "w-100 d-flex flex-row py-1"
                style={{gap:"0.5rem"}}
                key = {playlist.id}
                id = {playlist.id}
                name = {playlist.title}
                role = "button"
                onClick = {()=>{props.index===1 ? clickHandler(playlist):clickFromFilter(playlist)}}
              >
                <div>
                  {
                    props.index === 1 ?
                    video_ids_in_playlist && video_ids_in_playlist.length && 
                    video_ids_in_playlist.includes(playlist.id) ? 
                    <img src = {but_ON} alt = "" /> : 
                    <img src = {but_OFF} alt = ""/> : ""
                  }
                </div>
                <h6 className = "text-secondary">{playlist.title}</h6>
              </div>
          )) :''
        }
    </>
  )
}

export default AddVideoToPlaylistList;
