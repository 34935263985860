import React, {useState} from 'react';
import { addTag } from '../../actions/AdminFilterActions';
import { useDispatch } from 'react-redux';
import close from '../../app-main-content/icons/close.svg'


const AddTag = (props) => {

    const [tag, setTag] = useState("")

    const dispatch = useDispatch()
    
    const submitHandler = (e) => {
        e.preventDefault()
        if(/^[a-zA-Z0-9\s]*$/.test(tag) && tag.length>1){
        dispatch(addTag(tag))
        } else {
        alert("Please, Provide Valid Name")
        }
        setTag("")
        props.setIsActive(false)
    }

    return (
        <>
            <div className = "me-2" style={{width:"300px"}}>
                <div className = 'w-100 bg-secondary d-flex justify-content-between py-2 px-2 rounded-top'>
                    <div className='text-white px-3' style={{fontWeight: 500}}>Create Tag</div>
                    <img src={close} alt = "close" role="button" onClick={()=>{props.setIsActive(false)}}/>
                </div>
                <div className='col-12 p-3 rounded-bottom' style={{background:"#F3F9FF", gap:"1rem"}}>
                    <div className='w-100'>
                    <input
                        id="playlist-input"
                        className='w-100 border-0 p-2 my-3 text-secondary'
                        style={{background:"#DDE9F4"}}
                        type = "text"
                        placeholder = "Name..."
                        value = {tag}
                        onChange = {(e) => setTag(e.target.value)}
                        required
                    />
                    <button className='w-100 py-1 rounded-pill border-info text-info mt-1 mb-3' 
                        style={{fontWeight:500}}
                        onClick={submitHandler}
                        >Create</button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddTag;