import React, {useEffect, useState, useRef} from 'react'
import { getMoods } from '../../actions/filterActions'
import { useDispatch, useSelector } from 'react-redux'
import FilterComponent from './FilterComponent';
import AdminFilterComponent from '../../adminContent/AdminVideos/AdminFilterComponent';

const Moods = (props) => {

    const dispatch = useDispatch()

    const moodList = useSelector(state => state.moods)
  
    const {error, loading, moods} = moodList

    const [moodActive, setMoodActive] = useState(false)

    const [moodValue, setMoodValue] = useState()

    const searchMoodRef = useRef()

    useEffect(()=>{
 
        dispatch(getMoods(props.filterList))
    
      },[props.filterList])


    useEffect(()=>{
        document.addEventListener("mousedown", (event) => {
            if (searchMoodRef == null || searchMoodRef.current == null || !searchMoodRef.current.contains(event.target)){
            setMoodActive(false)
            }
        })
    }) 


    const chooseHandler = (mood) => {
        if (props.filterList.filter(g => g[0] === mood.id && g[2] === 2 ).length === 0 ){
            props.setFilterList((prevArr)=>{ return[...prevArr, [mood.id, mood.title, 2]]})
        }
        setMoodActive(false)
    }

    return (
        <>
            {
                props.index === 1 ? 
                <AdminFilterComponent
                    data = {moods}
                    setValue = {setMoodValue}
                    setActive = {setMoodActive}
                    value = {moodValue}
                    isActive = {moodActive}
                    chooseHandler = {chooseHandler}
                    componentRef = {searchMoodRef}
                    title = "Moods"
                    loading = {loading}
                /> :
                <FilterComponent
                    data = {moods}
                    setValue = {setMoodValue}
                    setActive = {setMoodActive}
                    value = {moodValue}
                    isActive = {moodActive}
                    chooseHandler = {chooseHandler}
                    componentRef = {searchMoodRef}
                    title = "Moods"
                    loading = {loading}
            />
            }
        </>
    );
};

export default Moods;