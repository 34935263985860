import React, {useEffect, useState, useRef} from 'react';
import { getArtists } from '../../actions/filterActions'
import { useDispatch, useSelector } from 'react-redux'
import FilterComponent from './FilterComponent';
import AdminFilterComponent from '../../adminContent/AdminVideos/AdminFilterComponent';

const Artists = (props) => {

    const searchArtistRef = useRef()

    const dispatch = useDispatch()

    const artistList = useSelector(state => state.artists)
  
    const {error, loading, artists} = artistList

    const [artistActive, setArtistActive] = useState(false)

    const [artistValue, setArtistValue] = useState("")

    const [page, setPage] = useState(1)

    const handleScroll = event => {
        const {scrollTop, clientHeight, scrollHeight} = event.currentTarget;
        let h
        h = Math.round(scrollHeight - scrollTop)
        if(h === clientHeight || h+1 === clientHeight || h-1 === clientHeight){
          setPage(prev => prev + 1)
        }
      }

    useEffect(()=>{
        const identifer = setTimeout(()=>{
            if (artistValue){
                dispatch(getArtists(artistValue, props.filterList, 1, 0))
            } else {
                dispatch(getArtists(" ", props.filterList, 1, 0))
            }
        }, 500)  

        return () => { clearTimeout(identifer) }

    },[artistValue, props.filterList])


    useEffect(()=>{
        if (artistValue){
            dispatch(getArtists(artistValue, props.filterList, page, 1))
        } else {
            dispatch(getArtists(" ", props.filterList, page, 1))
        }
    }, [page])

    useEffect(()=>{
        document.addEventListener("mousedown", (event) => {
            if (searchArtistRef == null || searchArtistRef.current == null || !searchArtistRef.current.contains(event.target)){
            setArtistActive(false)
            }
        })
    }) 


    const chooseHandler = (artist) => {
        if (props.filterList.filter(g => g[0] === artist.id && g[2] === 3 ).length === 0 ){
            props.setFilterList((prevArr)=>{ return[...prevArr, [artist.id, artist.title, 3]]})
        }
        setArtistActive(false)
        setArtistValue('')
    }

    return (
        <>
            {
                props.index === 1 ? 
                <AdminFilterComponent
                    data = {artists}
                    setValue = {setArtistValue}
                    setActive = {setArtistActive}
                    value = {artistValue}
                    isActive = {artistActive}
                    chooseHandler = {chooseHandler}
                    componentRef = {searchArtistRef}
                    handleScroll = {handleScroll}
                    title = "Artists"
                    loading = {loading}
                /> :
                <FilterComponent
                    data = {artists}
                    setValue = {setArtistValue}
                    setActive = {setArtistActive}
                    value = {artistValue}
                    isActive = {artistActive}
                    chooseHandler = {chooseHandler}
                    componentRef = {searchArtistRef}
                    handleScroll = {handleScroll}
                    title = "Artists"
                    loading = {loading}
            />
            }
        </>
    );
};

export default Artists;