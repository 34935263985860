import React from 'react';
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';

function Themes(props) {

    const navigate = useNavigate()

    const groupList = useSelector(state => state.allPlaylists)
  
    const {allPlaylists} = groupList

    const grClickHandler = (group_id, playlist_id, video_id) => {
        localStorage.setItem("group_id", group_id);
        localStorage.setItem('playlist_id', playlist_id); 
        localStorage.setItem('video_id', video_id); 
        if(props.user){
            navigate(encodeURI(`/home?g=${group_id}&p=${playlist_id}&v=${video_id}`))
        } else {
            navigate('/login')
        }
    }

    const plClickHandler = (e, group_id, playlist_id, video_id) => {
        e.stopPropagation()
        localStorage.setItem("group_id", group_id);
        localStorage.setItem('playlist_id', playlist_id); 
        localStorage.setItem('video_id', video_id); 
        if (props.user){
            navigate(encodeURI(`/home?g=${group_id}&p=${playlist_id}&v=${video_id}`))
        } else {
            navigate('/login')
        }
    }


    return (
        <li className="nav-item dropdown">
            <a className="nav-link btn btn-primary dropdown-toggle mx-2 px-0" href="#" role="button"
                id="themesDropdownLink" data-bs-toggle="dropdown" aria-expanded="false">
                Themes
            </a>

            <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink" id="themesDropdown">
                {
                    allPlaylists && allPlaylists.length ? 
                    allPlaylists.map(playlist => 
                    <li key = {playlist.id} 
                        onClick={()=>
                            grClickHandler(playlist.id, playlist.playlists[0].id, playlist.playlists[0].video_id)}>
                        <a className="dropdown-item group-item" href="#">{playlist.title}</a>
                        <ul className="dropdown-menu dropdown-submenu">
                            {
                                playlist.playlists && playlist.playlists.length?
                                playlist.playlists.map(pl=>
                                <li key = {pl.id} 
                                className='me-3'
                                style={{cursor:'pointer', overflow:'hidden'}} 
                                onClick={(e)=>plClickHandler(e, playlist.id, pl.id, pl.video_id)}>
                                    <a className="dropdown-item playlist-item">{pl.title}</a>
                                </li>):''
                            }
                        </ul>
                    </li>)
                    :''
                }
            </ul>
        </li>
    );

}

export default Themes;