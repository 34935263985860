import React, {useEffect, useState, useRef}  from 'react';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import Back from '../adminIcons/Back.svg'
import { getAlbumsForAdmin, getTracksLength } from '../../actions/AdminFilterActions';


const Albums = (props) => {

    const { adminAlbums, index, albumsLength } = useSelector(state=>state.adminAlbums)

    const dispatch = useDispatch()

    const [page, setPage] = useState(1)

    const handlePageClick = (event) => {
        setPage(event.selected+1)
    }

    function toTimeString(seconds) {
        let inTime = (new Date(seconds * 1000)).toUTCString().match(/(\d\d:\d\d:\d\d)/)[0];
        if(seconds < 3600){
            inTime = inTime.slice(3, 8)
        }
        return (inTime)
      }


    useEffect(()=>{
        dispatch(getAlbumsForAdmin(props.albumValue, page, 0))
    },[page])

    const albumClickHandler = (album) => {
        dispatch(getTracksLength(album.id))
        props.setAlbum(album)
        props.setComponent(3)
    }   

    return (
        <>
            <div>
                <div className='d-flex pb-4' style={{gap:"1rem"}}>
                    <img 
                        src = {Back} 
                        role = "button"
                        alt = "" 
                        onClick={()=>props.setComponent(1)}/>
                    <div className = "fw-bold fs-7 text-success">ADD TRACK</div>
                    <div className = "fw-bold fs-7 text-secondary">STEP 2</div>
                    <div className = "fs-7 text-secondary">Choose album</div>
                </div>
                <div className = "d-flex bg-dark py-2 rounded-top align-items-center">
                    <div className="px-2 text-info" style={{width:"54%"}}>
                        Album
                    </div>
                    <div className="px-2 text-info" style={{width:"11%"}}>Genre</div>
                    <div className="px-2 text-info text-wrap" style={{width:"10%"}}>Track Numbers</div>
                    <div className="px-2 text-info" style={{width:"10%"}}>Duration</div>
                    <div className="px-2 text-info" style={{width:"15%"}}>Catalogue</div>
                </div>
                {
                    adminAlbums && adminAlbums.length ? 
                    adminAlbums.map((album, index)=>(
                        <div className = "d-flex bg-secondary py-2 align-items-center border-bottom border-light"
                            key={index}
                            role="button"
                            onClick={()=>albumClickHandler(album)}>
                            <div className="px-2 text-info" style={{width:"8%"}}>
                                <img 
                                    src = {`https://img.cugate.com/?o=album&i=${album.id}&s=300`}
                                    style={{width:"4rem", height:"4rem"}}/>
                            </div>
                            <div className="px-2 text-white" style={{width:"46%"}}>{album.title}</div>
                            <div className="px-2 text-white" style={{width:"11%"}}>{album.genre.title}</div>
                            <div className="px-2 text-white text-wrap" style={{width:"10%"}}>{album.number_of_tracks}</div>
                            <div className="px-2 text-white" style={{width:"10%"}}>{toTimeString(album.duration)}</div>
                            <div className="px-2 text-white" style={{width:"15%"}}>{album.catalogue_num}</div>
                        </div>
                    )): ""
                }
                {
                    albumsLength &&  
                    <div className = 'd-flex justify-content-end bg-light'>
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel=">"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={3}
                            pageCount={Math.ceil(albumsLength/10)}
                            previousLabel="<"
                            renderOnZeroPageCount={null}
                            marginPagesDisplayed = {1}
                            containerClassName = "d-flex py-2 col-4 justify-content-end"
                            pageClassName = 'text-info fw-bold col-1 py-1 rounded bg-page border border-lighter list-unstyled m-1 text-center'
                            previousClassName = 'col-1 py-1 rounded bg-light list-unstyled m-1 text-center'
                            previousLinkClassName = 'text-secondary fw-bold col-1 py-1 rounded bg-light text-decoration-none m-1 text-center'
                            nextClassName = 'col-1 py-1 rounded bg-light list-unstyled m-1 text-center'
                            nextLinkClassName = 'text-secondary fw-bold col-1 py-1 rounded bg-light text-decoration-none m-1 text-center'
                            breakClassName = 'col-1 text-info list-unstyled d-flex align-items-end justify-content-center fs-2'
                            activeClassName = 'bg-success text-white fw-bold'
                        />
                    </div> 
                }
            </div>
        </>
    );
};

export default Albums;