import React from 'react'
import Slide from "./Slide";
import VideoInfo from './VideoInfo'

export default function Slides(props) {
    const sWRightSpaceClass = props.displayCount === 3 ? 'slide-wrapper-for-four' : 'slide-wrapper-for-six'

    return (
        <>
            { props.tvData && props.tvData.length ?
                props.tvData.map(
                    (data, i) =>
                        <div 
                            onClick={() => props.slideClickHandler(data)}
                            className={'position-relative slide-wrapper ' + sWRightSpaceClass}
                            role='button'
                            key={i}
                            style={{
                                width: `${props.slideWrapperWidth}vw`,
                                // height: '100%'
                            }}
                        >
                            <Slide
                                type = {props.type}
                                showTitle = {props.showTitle}
                                tvData = {data}
                                displayCount={props.displayCount}
                                slideClickHandler = {props.slideClickHandler}
                                width =  {"100%"}
                            />
                            {
                                props.showTitle && <VideoInfo data = {data}/>
                            }
                        </div>
                )
                : ''
            }
        </>
    )
}
