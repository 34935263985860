import React, { useState, useEffect } from 'react'
import AddVideoToPlaylistContent from '../playlistAdds/AddVideoToPlaylistContent'
import ShareContent from './ShareContent'
import plus from '../icons/Plus.svg'
import shareIcon from '../icons/shareIcon.svg'
import Modal from '../Modal'
import useWindowDimentions from '../useWindowDimentions'

const VideoNameAndActions = ({video}) => {

  const [isActive, setIsActive] = useState(false)
  const [shareActive, setShareActive] = useState(false)

  const {width, height} = useWindowDimentions()

  const [right, setRight] = useState(0)

  const [top, setTop] = useState(0)

  const get_members = (data) =>{

    let members = '' 
    
    if (data.artists && data.artists.length){
        members = data.artists
    }

    if (data.composers && data.composers.length){
        let c = data.composers.split(',')
        for (let i in c){
            if (!members.includes(c[i])){
                members += ', '+ c[i]
            }
        }    
    }
    return members
}


  const addClickHandler = (e) => {
    setRight(width-e.clientX)
    if(height - e.clientY > 250){
      setTop(e.clientY-20)
    } else {
      setTop(height-250)
    }
    setIsActive(true)
}

const shareClickHandler = (e) => {
    setRight(width-e.clientX+5)
    setTop(e.clientY-20)
    setShareActive(true)
  }

  return(
    <div className='col-9 col-lg-12'>
      {
        video.title ?
        <div className = "w-100 position-relative text-white d-flex justify-content-between align-items-center mt-2">
          <div className = "text-white fw-bold"
            style={{fontSize:"1.5rem"}}
            title = {video.title}>{video.title+ ' '} 
          <span className = "fs-6 fw-normal fst-italic text-info overflow-hidden" title = {get_members(video)}>{get_members(video)}</span>
          </div>
        </div>: ""
      }
      <div className = "col-12 pt-2 d-flex flex-row justify-content-end pe-4 pe-lg-0">
          <div className = "col-5 col-lg-2 col-md-3 px-0 px-lg-3 d-flex justify-content-end align-items-center position-relative"
            style={{gap:"1rem"}}>

                <img className='me-3' 
                  role = "button" 
                  style={{width:"1.3rem"}}
                  onClick={(e)=>shareClickHandler(e)} 
                  src = {shareIcon} 
                  alt = "Share"/>

                  <img 
                    role = "button"
                    style={{width:"1.3rem"}} 
                    onClick = {(e)=>addClickHandler(e)} 
                    src = {plus} 
                    alt = "Add"/>

          </div>
      </div>
      <Modal 
            trigger = {isActive}
            setTrigger = {setIsActive}
            top = {top}
            right = {right}
            content = {
                <AddVideoToPlaylistContent
                    setIsActive = {setIsActive}
                    video_list = {[video.id]}
                    index = {1}
                    video = {video}
                /> 
              }
          />
          <Modal 
            trigger = {shareActive}
            setTrigger = {setShareActive}
            top = {top}
            right = {right}
            content = {
              <ShareContent
                setShareActive = {setShareActive}
                video = {video}
              /> 
            }
        /> 
    </div>

  )
}

export default VideoNameAndActions;
