import React from 'react';
import { useSelector } from 'react-redux';

const AnalyzeHeader = ({setActiveData, setResultLink, activeHeader, setActiveHeader}) => {

    const { watermark_info, footprint_info, end_watermark, end_footprint} = useSelector(state=>state.analyze_info)

    return (
        <div className = "container-fluid bg-secondary px-0 py-lg-4 py-3 d-flex justify-content-center">
            <div className = "col-12 col-lg-10 px-0 px-lg-5 d-flex flex-column flex-md-row flex-lg-row justify-content-between align-items-center">
                <div className = "col-12 col-lg-3 col-md-6 text-center text-lg-start text-info fs-4 mb-2 mb-lg-0">      
                    ANALYSIS RESULTS
                </div>
                <div className = "col-12 col-lg-3 col-md-6 d-flex flex-wrap text-center justify-content-center justify-content-lg-start"
                    style={{gap:"0.8rem"}}
                >
                    { end_watermark && watermark_info.wm_track ?
                        <div role="button" className = { 
                                activeHeader === 1 ? 
                                    "bg-info text-white px-3 py-2 border border-info rounded-pill" :
                                    "text-info px-3 py-2 border border-info rounded-pill"
                                }
                            style={{width:"8.5rem"}}
                            onClick={()=>{
                                setActiveData(watermark_info.wm_track); 
                                setResultLink(watermark_info.result_link);
                                setActiveHeader(1)
                            }}
                        >WATERMARK</div> : ""
                    }
                    {
                        end_footprint && footprint_info.fp_track ?
                        <div role="button" className = { activeHeader === 2 ? 
                            "bg-info text-white px-3 py-2 border border-info rounded-pill" :
                                    "text-info px-3 py-2 border border-info rounded-pill"
                                }
                            style={{width:"8.5rem"}}
                        onClick={()=>{
                            setActiveData(footprint_info.fp_track); 
                            setResultLink(footprint_info.result_link);
                            setActiveHeader(2)
                        }}>FOOTPRINT</div> : ""
                    }
                </div>
            </div>
        </div>
    );
}

export default AnalyzeHeader;