import React, {useState} from 'react';
import ChooseVideo from './ChooseVideo';
import ChoosePlaylist from './ChoosePlaylist';
import ChooseTheme from './ChooseTheme';
import BannerTitle from './BannerTitle';
import ExitIcon from '../adminIcons/CrossIconInfo.svg'

const AddBannerVideo = (props) => {

    const [index, setIndex] = useState(1)

    const [video, setVideo] = useState()
    const [playlist, setPlaylist] = useState()
    const [themeId, setThemeId] = useState()

    const exitHandler = () => {
        setIndex(1)
        setVideo()
        setPlaylist()
        setThemeId()
        props.setIsActive(false)
    }

    return (
        <div
            style={{
                backgroundColor: "rgba(0,0,0,.6)",
                width:props.width, 
                height:props.height, 
                maxHeight:"fit-content", 
                // overflowY:"scroll",
                paddingTop:"109px"
            }}>
            <div className = 'w-100 d-flex justify-content-center'>
                <div className='col-8 bg-primary rounded px-5 pb-5 pt-3'>
                    <div className = 'd-flex justify-content-end'>
                        <img 
                            src = {ExitIcon}
                            role = 'button'
                            onClick={exitHandler}/>
                    </div>
                    {
                        index === 1 ? 
                        <ChooseVideo
                            setIndex = {setIndex}
                            setVideo = {setVideo}
                        /> : index === 2 ? 
                        <ChoosePlaylist
                            setIndex = {setIndex}
                            setPlaylist = {setPlaylist}
                        /> : index === 3 ? 
                        <ChooseTheme
                            setThemeId = {setThemeId}
                            setIndex = {setIndex}
                        /> : index === 4 ? 
                        <BannerTitle
                            video = {video}
                            playlist = {playlist}
                            themeId = {themeId}
                            setIsActive = {props.setIsActive}
                        />: ""
                    }
                </div>
            </div>      
        </div>
    );
};

export default AddBannerVideo;