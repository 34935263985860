
export const GET_PLAYLISTS_FOR_ADMIN_REQUEST = 'GET_PLAYLISTS_FOR_ADMIN_REQUEST'
export const GET_PLAYLISTS_FOR_ADMIN_SUCCESS = 'GET_PLAYLISTS_FOR_ADMIN_SUCCESS'
export const GET_PLAYLISTS_FOR_ADMIN_FAIL = 'GET_PLAYLISTS_FOR_ADMIN_FAIL'

export const PLAYLISTS_SEARCH_RESULTS_REQUEST = 'PLAYLISTS_SEARCH_RESULTS_REQUEST'
export const PLAYLISTS_SEARCH_RESULTS_SUCCESS = 'PLAYLISTS_SEARCH_RESULTS_SUCCESS'
export const PLAYLISTS_SEARCH_RESULTS_FAIL = 'PLAYLISTS_SEARCH_RESULTS_FAIL'

export const ADD_PLAYLIST_FOR_ADMIN_REQUEST = 'ADD_PLAYLIST_FOR_ADMIN_REQUEST'
export const ADD_PLAYLIST_FOR_ADMIN_SUCCESS = 'ADD_PLAYLIST_FOR_ADMIN_SUCCESS'
export const ADD_PLAYLIST_FOR_ADMIN_FAIL = 'ADD_PLAYLIST_FOR_ADMIN_FAIL'

export const DELETE_PLAYLIST_FOR_ADMIN_REQUEST = 'DELETE_PLAYLIST_FOR_ADMIN_REQUEST'
export const DELETE_PLAYLIST_FOR_ADMIN_SUCCESS = 'DELETE_PLAYLIST_FOR_ADMIN_SUCCESS'
export const DELETE_PLAYLIST_FOR_ADMIN_FAIL = 'DELETE_PLAYLIST_FOR_ADMIN_FAIL'

export const PLAYLISTS_LENGTH_REQUEST = 'PLAYLISTS_LENGTH_REQUEST'
export const PLAYLISTS_LENGTH_SUCCESS = 'PLAYLISTS_LENGTH_SUCCESS'
export const PLAYLISTS_LENGTH_FAIL = 'PLAYLISTS_LENGTH_FAIL'

export const PLAYLISTS_LENGTH_FOR_SEARCH_REQUEST = 'PLAYLISTS_LENGTH_FOR_SEARCH_REQUEST'
export const PLAYLISTS_LENGTH_FOR_SEARCH_SUCCESS = 'PLAYLISTS_LENGTH_FOR_SEARCH_SUCCESS'
export const PLAYLISTS_LENGTH_FOR_SEARCH_FAIL = 'PLAYLISTS_LENGTH_FOR_SEARCH_FAIL'

export const PLAYLISTS_SEARCH_SUGGESTIONS_REQUEST = 'PLAYLISTS_SEARCH_SUGGESTIONS_REQUEST'
export const PLAYLISTS_SEARCH_SUGGESTIONS_SUCCESS = 'PLAYLISTS_SEARCH_SUGGESTIONS_SUCCESS'
export const PLAYLISTS_SEARCH_SUGGESTIONS_FAIL = 'PLAYLISTS_SEARCH_SUGGESTIONS_FAIL'

export const PLAYLIST_INFO_REQUEST = 'PLAYLIST_INFO_REQUEST'
export const PLAYLIST_INFO_SUCCESS = 'PLAYLIST_INFO_SUCCESS'
export const PLAYLIST_INFO_FAIL = 'PLAYLIST_INFO_FAIL'

export const DELETE_PLAYLIST_VIDEO_FOR_ADMIN_REQUEST = 'DELETE_PLAYLIST_VIDEO_FOR_ADMIN_REQUEST'
export const DELETE_PLAYLIST_VIDEO_FOR_ADMIN_SUCCESS = 'DELETE_PLAYLIST_VIDEO_FOR_ADMIN_SUCCESS'
export const DELETE_PLAYLIST_VIDEO_FOR_ADMIN_FAIL = 'DELETE_PLAYLIST_VIDEO_FOR_ADMIN_FAIL'

export const EDIT_PLAYLIST_FOR_ADMIN_REQUEST = 'EDIT_PLAYLIST_FOR_ADMIN_REQUEST'
export const EDIT_PLAYLIST_FOR_ADMIN_SUCCESS = 'EDIT_PLAYLIST_FOR_ADMIN_SUCCESS'
export const EDIT_PLAYLIST_FOR_ADMIN_FAIL = 'EDIT_PLAYLIST_FOR_ADMIN_FAIL'

export const ADD_PLAYLIST_TO_GROUP_REQUEST = 'ADD_PLAYLIST_TO_GROUP_REQUEST'
export const ADD_PLAYLIST_TO_GROUP_SUCCESS = 'ADD_PLAYLIST_TO_GROUP_SUCCESS'
export const ADD_PLAYLIST_TO_GROUP_FAIL = 'ADD_PLAYLIST_TO_GROUP_FAIL'

export const DELETE_PLAYLIST_FROM_GROUP_REQUEST = 'DELETE_PLAYLIST_FROM_GROUP_REQUEST'
export const DELETE_PLAYLIST_FROM_GROUP_SUCCESS = 'DELETE_PLAYLIST_FROM_GROUP_SUCCESS'
export const DELETE_PLAYLIST_FROM_GROUP_FAIL = 'DELETE_PLAYLIST_FROM_GROUP_FAIL'

export const EDIT_PLAYLIST_THUMBNAIL_REQUEST = 'EDIT_PLAYLIST_THUMBNAIL_REQUEST'
export const EDIT_PLAYLIST_THUMBNAIL_SUCCESS = 'EDIT_PLAYLIST_THUMBNAIL_SUCCESS'
export const EDIT_PLAYLIST_THUMBNAIL_FAIL = 'EDIT_PLAYLIST_THUMBNAIL_FAIL'


