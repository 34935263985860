import {
    
    GET_PLAYLISTS_FOR_ADMIN_REQUEST,
    GET_PLAYLISTS_FOR_ADMIN_SUCCESS,
    GET_PLAYLISTS_FOR_ADMIN_FAIL,

    PLAYLISTS_SEARCH_RESULTS_REQUEST,
    PLAYLISTS_SEARCH_RESULTS_SUCCESS,
    PLAYLISTS_SEARCH_RESULTS_FAIL,
  
    ADD_PLAYLIST_FOR_ADMIN_REQUEST,
    ADD_PLAYLIST_FOR_ADMIN_SUCCESS,
    ADD_PLAYLIST_FOR_ADMIN_FAIL,

    DELETE_PLAYLIST_FOR_ADMIN_REQUEST,
    DELETE_PLAYLIST_FOR_ADMIN_SUCCESS,
    DELETE_PLAYLIST_FOR_ADMIN_FAIL,

    PLAYLISTS_LENGTH_REQUEST,
    PLAYLISTS_LENGTH_SUCCESS,
    PLAYLISTS_LENGTH_FAIL,

    PLAYLISTS_LENGTH_FOR_SEARCH_REQUEST,
    PLAYLISTS_LENGTH_FOR_SEARCH_SUCCESS,
    PLAYLISTS_LENGTH_FOR_SEARCH_FAIL,

    PLAYLISTS_SEARCH_SUGGESTIONS_REQUEST,
    PLAYLISTS_SEARCH_SUGGESTIONS_SUCCESS,
    PLAYLISTS_SEARCH_SUGGESTIONS_FAIL,

    PLAYLIST_INFO_REQUEST,
    PLAYLIST_INFO_SUCCESS,
    PLAYLIST_INFO_FAIL,

    DELETE_PLAYLIST_VIDEO_FOR_ADMIN_REQUEST,
    DELETE_PLAYLIST_VIDEO_FOR_ADMIN_SUCCESS,
    DELETE_PLAYLIST_VIDEO_FOR_ADMIN_FAIL,


    ADD_PLAYLIST_TO_GROUP_REQUEST,
    ADD_PLAYLIST_TO_GROUP_SUCCESS,
    ADD_PLAYLIST_TO_GROUP_FAIL,
  
    DELETE_PLAYLIST_FROM_GROUP_REQUEST,
    DELETE_PLAYLIST_FROM_GROUP_SUCCESS,
    DELETE_PLAYLIST_FROM_GROUP_FAIL,
  

} from '../constants/AdminPlaylistConstants'


const initialState = {
    adminPlaylists : [],
    loadingAdminPlaylists: false,
    index: null,
    playlistsLength: null,
  }

export const adminPlaylistsReducer = (state = initialState , action) => {
    switch (action.type){

      case PLAYLISTS_LENGTH_REQUEST:
        return { 
          loadingAdminPlaylists: true,
          ...state,
        }
  
      case PLAYLISTS_LENGTH_SUCCESS:
        return { 
          ...state,
          playlistsLength: action.payload
        }
  
      case PLAYLISTS_LENGTH_FAIL:
        return { 
          ...state,
          loadingAdminPlaylists: false,
        }

      case PLAYLISTS_LENGTH_FOR_SEARCH_REQUEST:
        return { 
          ...state,
        }
  
      case PLAYLISTS_LENGTH_FOR_SEARCH_SUCCESS:
        return { 
          ...state,
          playlistsLength: action.payload
        }
  
      case PLAYLISTS_LENGTH_FOR_SEARCH_FAIL:
        return { 
          index: action.index,
          ...state
        }


      case GET_PLAYLISTS_FOR_ADMIN_REQUEST:
        return { 
          index: action.index,
          ...state,
          loadingAdminPlaylists: true,
        }
  
      case GET_PLAYLISTS_FOR_ADMIN_SUCCESS:
        return { 
          ...state,
          loadingAdminPlaylists: false, 
          adminPlaylists: action.payload,
          index: action.index
        }
  
  
      case PLAYLISTS_SEARCH_RESULTS_REQUEST:
        return{
          index: action.index,
          ...state,
        }
  
      case PLAYLISTS_SEARCH_RESULTS_SUCCESS:
        if(action.loadIndex===2){
          return{
            ...state,
            adminPlaylists: [...state.adminPlaylists, ...action.payload],
            index: action.index
          }
        }  else {
          return{
            ...state,
            adminPlaylists: action.payload,
            index: action.index
          }
        }
  
      case PLAYLISTS_SEARCH_RESULTS_FAIL:
        return {
          index: action.index,
          ...state,
        }
  
      case ADD_PLAYLIST_FOR_ADMIN_REQUEST:
        return{
          ...state
        }
  
      case ADD_PLAYLIST_FOR_ADMIN_SUCCESS:
        return{
          adminPlaylists: [action.payload, ...state.adminPlaylists],
          ...state
        }
  
      case ADD_PLAYLIST_FOR_ADMIN_FAIL:
        return {
          ...state
        }
          
      case ADD_PLAYLIST_TO_GROUP_REQUEST:
        return{
          ...state
        }
  
      case ADD_PLAYLIST_TO_GROUP_SUCCESS:
        let pl = state.adminPlaylists.find(pl=>pl.id===action.playlist_id)
        pl.groups.push(action.group.id)
        return{
          ...state
        }
  
      case ADD_PLAYLIST_TO_GROUP_FAIL:
        return {
          ...state
        }
                
        case DELETE_PLAYLIST_FROM_GROUP_REQUEST:
          return{
            ...state
          }
    
        case DELETE_PLAYLIST_FROM_GROUP_SUCCESS:
          let plst = state.adminPlaylists.find(pl=>pl.id===action.playlist_id)
          plst.groups = plst.groups.filter(group=>group!==action.group_id)
          return{
            ...state
          }
    
        case DELETE_PLAYLIST_FROM_GROUP_FAIL:
          return {
            ...state
          }
  
      case DELETE_PLAYLIST_FOR_ADMIN_REQUEST:
        return{
          ...state
        }
  
      case DELETE_PLAYLIST_FOR_ADMIN_SUCCESS:
        return{
          ...state,
          adminPlaylists: state.adminPlaylists.filter(pl => pl.id!=action.playlist_id),
        }
  
      case DELETE_PLAYLIST_FOR_ADMIN_FAIL:
        return {
          ...state
        }

  
      case GET_PLAYLISTS_FOR_ADMIN_FAIL:
        return { 
          ...state,
          loadingAdminPlaylists: false, 
          index: action.index,
          error: action.payload }
  
      default:
        return state
    }
  }


  export const playlistsSearchSuggestionsReducer = (state = { playlistSearchSuggestions: [] }, action) => {
    switch (action.type){
  
      case PLAYLISTS_SEARCH_SUGGESTIONS_SUCCESS:
        return { loading: false, playlistSearchSuggestions:  action.payload }
  
      case PLAYLISTS_SEARCH_SUGGESTIONS_FAIL:
        return { loading: false, error: action.payload }
  
      default:
        return state
    }
  }

  export const playlistInfoReducer = (state = { playlistInfo: [] }, action) => {
    switch (action.type){

      case PLAYLIST_INFO_REQUEST:
        return { loadingInfo: false, playlistInfo:  [] }
  
      case PLAYLIST_INFO_SUCCESS:
        return { loadingInfo: false, playlistInfo:  action.payload }
  
      case PLAYLIST_INFO_FAIL:
        return { loadingInfo: false, error: action.payload }

      case DELETE_PLAYLIST_VIDEO_FOR_ADMIN_REQUEST:
        return { ...state }
  
      case DELETE_PLAYLIST_VIDEO_FOR_ADMIN_SUCCESS:
        let videos = state.playlistInfo.videos.filter(video=>parseInt(video.id)!==parseInt(action.video_id))
        videos.forEach((video)=>{
          if (video.position > action.video_position){
            video.position -= 1 
          }
        })

        state.playlistInfo.videos = videos
        return { 
          ...state, 
          playlistInfo: [...state.playlistInfo],
          }
    
        case DELETE_PLAYLIST_VIDEO_FOR_ADMIN_FAIL:
          return { ...state }
  
  
      default:
        return state
    }
  }

