import React from 'react';
import cugate from '../images/cugate.png'


function LandingFooter(props) {
    return (
        <section id="footerSection">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex flex-column justify-content-center align-items-center mt-2 mb-4">
                            <img src={cugate} id="cugateLogoFooter" className="my-4" />
                            <div id="footerText" className="text-white">© 2024 CuGate AG. All rights reserved.</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default LandingFooter;