import { Outlet } from "react-router-dom";
import Header from "./header_footer/Header";

const UserLayout = (props) => {
    return (
      <div className="">
        <Header myPlaylistsRef = {props.myPlaylistsRef}/>
        <Outlet />
      </div>
    );
  };
  
  export default UserLayout;