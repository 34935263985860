import React, {useState, useEffect } from 'react';
import './ProgressSlider.css'

const  ProgressSlider = (props) => {

    const [position, setPosition] = useState(0)

    useEffect(()=>{
        setPosition(props.progress)
    },[props.progress])

    return (
        <div className='progress-slider-container' style = {{'--progress-bar-height': `${props.height}`}}>
            <div 
                className='progress-bar-cover'
                style={{width:`${position}%`}}
            ></div>
            <div className='thumb' style={{
                left: `${position}%`,
                height:`${props.height}`
            }}></div>
            <input 
                type='range' 
                step='0.01' 
                style = {{height:`${props.height}`}}
                className ='progress-range'
                onChange={props.onChange}
                // value={props.progress}
            />
        </div>
    );
}

export default ProgressSlider;