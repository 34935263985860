import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAIL,

    LOAD_USER_REQUEST,
    LOAD_USER_SUCCESS,
    LOAD_USER_FAIL,

    AUTHENTICATION_REQUEST,
    AUTHENTICATION_SUCCESS,
    AUTHENTICATION_FAIL,

    CHECK_EMAIL_REQUEST,
    CHECK_EMAIL_FAIL,
    CHECK_EMAIL_SUCCESS,
  
    SIGNUP_REQUEST,
    SIGNUP_SUCCESS,
    SIGNUP_FAIL,

    ACTIVATION_REQUEST,
    ACTIVATION_SUCCESS,
    ACTIVATION_FAIL,

    PASSWORD_RESET_REQUEST,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_FAIL,

    PASSWORD_RESET_CONFIRM_REQUEST,
    PASSWORD_RESET_CONFIRM_SUCCESS,
    PASSWORD_RESET_CONFIRM_FAIL,

    LOGOUT
} from '../constants/AuthConstants'

const initialState = {
    access: localStorage.getItem('access'),
    refresh: localStorage.getItem('refresh'),
    isAuthenticated: null,
    user: null,
    authErrorMessage: '',
    authConfirmMessage: ''
}


export const userReducer = (state = initialState, action) => {

    const {type, payload} = action;

    switch (type){

      case LOGIN_REQUEST:
        return { loginLoading: true, user: null}
  
      case LOGIN_SUCCESS:
        localStorage.setItem('access', payload.access)
        return { 
            loginLoading: false,
            ...state,
            isAuthenticated:true,
            access: payload.access,
            refresh: payload.refresh 
        }

        case AUTHENTICATION_REQUEST:
          return{
            authError: payload,
            userLoading: true,
            isAuthenticated: false
          }
  
        case AUTHENTICATION_SUCCESS:
          return {
            userLoading: true,
            ...state,
            isAuthenticated: true
          }


      case LOAD_USER_REQUEST:
        return{
            userLoading: true,
            user: null
        }

      case SIGNUP_REQUEST:
        return{
          userLoading: true,
          user: null
        }

      case SIGNUP_SUCCESS:
        return{
          userLoading: true,
          ...state,
          isAuthenticated: false,
          authConfirmMessage: action.confirmMessage
        }

      case SIGNUP_FAIL:
        return {
          authErrorMessage: action.payload
        }

      case LOAD_USER_SUCCESS:
        return{
          ...state,
          userLoading: false,
          user: payload
        }

      case ACTIVATION_REQUEST:
        return{
          userLoading: true,
            ...state
        }
      case ACTIVATION_SUCCESS:
        return{
          userLoading: false,
            ...state
        }

      case ACTIVATION_FAIL:
        return{
          userLoading: false,
            ...state
        }
        

      case LOAD_USER_FAIL:
        return{
          userLoading: false,
            ...state,
          authError: payload
        }


      case LOGOUT:
        localStorage.removeItem('access');
        localStorage.removeItem('refresh');
        return { 
            userLoading: false, 
            authError: payload,
            ...state,
            access: null,
            refresh: null,
            isAuthenticated: false,
            user: null
        }

      case AUTHENTICATION_FAIL:
        return {
          userLoading: false,
          authError: payload,
          ...state,
          isAuthenticated: false
        }

      case LOGIN_FAIL:
        localStorage.removeItem('access');
        localStorage.removeItem('refresh');
        return { 
            loginLoading: false, 
            authError: payload,
            ...state,
            access: null,
            refresh: null,
            isAuthenticated: false,
            user: null
        }
  
      default:
        return state
    }
  }


  export const checkEmailReducer = (state = {checked_email:false }, action) => {
    switch (action.type){
      case CHECK_EMAIL_REQUEST:
        return { loading: true}
  
      case CHECK_EMAIL_SUCCESS:
        return { loading: false, checked_email:  action.payload }
  
      case CHECK_EMAIL_FAIL:
        return { loading: false, message: action.payload }
  
      default:
        return state
    }
  }

  export const resetPasswordReducer = (state = { }, action) => {
    switch (action.type){
      case PASSWORD_RESET_REQUEST:
        return{
          loadingReset: true,
          ...state
        }

      case PASSWORD_RESET_SUCCESS:
        return{
            loadingReset: false,
            resetMessage: action.message,
            ...state
        }

      case PASSWORD_RESET_FAIL:
        return{
            loadingReset: false,
            resetError: action.payload,
            ...state
        }
  
      default:
        return state
    }
  }

  export const resetPasswordConfirmReducer = (state = {}, action) => {
    switch (action.type){
      case PASSWORD_RESET_CONFIRM_REQUEST:

      case PASSWORD_RESET_CONFIRM_SUCCESS:
        return{
            resetConfirmMessage: action.message,
        }

      case PASSWORD_RESET_CONFIRM_FAIL:
        return{
            resetConfirmError: action.payload,
        }
  
      default:
        return state
    }
  }