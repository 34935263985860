import React, {useState, useEffect} from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../../actions/AuthActions';

function Login() {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [message, setMessage] = useState()
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    const dispatch = useDispatch()

    const auth = useSelector(state => state.user)

    const { user, authError } = auth

    const video_id = localStorage.getItem('video_id') ? localStorage.getItem('video_id') : 491
    const playlist_id = localStorage.getItem('playlist_id') ? localStorage.getItem('playlist_id') : 33
    const group_id = localStorage.getItem('group_id') ? localStorage.getItem('group_id') : 8

    const link = `/home?p=${playlist_id}&v=${video_id}&g=${group_id}`

    useEffect(()=>{
      if(authError){
        setMessage(authError)
      }
    }, [authError])

    const navigate = useNavigate()
    
    const submitHandler = (e) => {
      e.preventDefault()
      if(!emailRegex.test(email)){
        setMessage("Please, Enter Valid Email")
      } else {
        dispatch(login(email, password))
      }
    }

    if (user){
        return <Navigate to = {link}/>
      } else {
      return (
        <div className = "container-fluid min-vh-100 d-flex align-items-center"
        style={{background:"linear-gradient(#051932, #07294C)", padding:0, margin:0, height:"100vh"}}>
            <div className='container d-flex flex-column align-items-center justify-content-center'
              style={{gap:"1.5rem"}}>
              {message && <div className = "col-10 col-md-8 col-lg-4 text-info text-center">{message}</div>}
              <button className = "col-10 col-md-8 col-lg-4 bg-secondary fw-bold border-0 rounded py-2" 
                onClick={()=>navigate('/register')}>Sign Up</button>

              <input className = "col-10 col-md-8 col-lg-4 p-2 border-0 rounded input" 
              type="email" 
              value = {email} 
              name = "email"
              autoComplete='email'
              placeholder="Email" 
              onChange = {(e) => {setEmail(e.target.value); setMessage('')}} />
    
              <input className = "col-10 col-md-8 col-lg-4 p-2 border-0 rounded input" 
                type="password" 
                value = {password} 
                name = "password"
                placeholder="Password" 
                autoComplete="password"
              onChange = {(e) => {setPassword(e.target.value); setMessage('')}} 
                  />

              <button className = "col-10 col-md-8 col-lg-4 text-primary bg-info fw-bold border-0 rounded py-2" onClick={submitHandler}
              >Sign In</button>
              <div className = "col-10 col-md-8 col-lg-4 text-center text-info fw-light text-decoration-underline" 
                role = "button"
                onClick={()=>navigate('/reset_password')}
                >Forgot Your Password?</div>
              </div>
        </div>
      )
    }

}

export default Login;