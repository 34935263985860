import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import Results from './Results';
import { executeFilter } from '../../actions/filterActions';
import { useParams } from 'react-router-dom'

function FilterResults(props) {

    const {error, loading, filterResults} = useSelector(state => state.filterResults)

    const {filterList} = useParams()

    const [lst, setLst] = useState()

    

    useEffect(()=>{
        let l = filterList.split('|').map(fl=>fl.split('!').map(i=>parseInt(i)))
        setLst(l)
    },[filterList])


    return (
        <>
            <Results
                filterList = {props.filterList}
                setFilterList = {props.setFilterList}
                data = {filterResults}
                title = {"FILTER RESULTS"}
                loading = {loading}
                error = {error}
                setVideo = {props.setVideo}
                filterActive = {props.filterActive}
                setFilterActive = {props.setFilterActive}
                searchFunc = {executeFilter}
                val = {lst}
            />
        </>
    );
}

export default FilterResults;