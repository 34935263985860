import React from 'react';
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import Edit_primary from '../adminIcons/Edit_primary.svg'
import CrossIcon from '../adminIcons/del.svg'
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { deleteGroup } from '../../actions/GroupsActions';

const ThemesItem = (props) => {

    const navigate = useNavigate()

    const dispatch = useDispatch()

    const { attributes, listeners, setNodeRef, transform, transition, isDragging  } = useSortable(
        {id: props.group.position})

    const style = {
        transition,
        transform: CSS.Transform.toString(transform),
    }


    const deleteHandler = () => {
        var answer = window.confirm("Do you really want to delete the Theme?");
        if (answer) {
            dispatch(deleteGroup(props.group.id, props.group.position))
        }
    }

    return (
        <div    
            id={props.group.position}
            ref = {setNodeRef}
            {...attributes}
            {...listeners}
            style = {style}
            className='p-3 border border-secondary rounded d-flex my-2'
            key = {props.index}>
            <div className = 'text-info col-5 text-center'>{props.group.title}</div>
            <div className = 'text-info col-2 text-center'>{props.group.position}</div>
            <div className = 'text-info col-2 text-center'>{props.group.number_of_playlists}</div>
            <div className = 'col-2 d-flex justify-content-center align-items-center'>
                {
                    props.group.status ? 
                    <div className = 'text-info'>ON</div> : <div className = 'text-info'>OFF</div>
                }
            </div>    
            <div className = 'd-flex col-1 justify-content-center'>
                <img 
                    className = 'p-1' 
                    style={{width:"22px"}}
                    src = {Edit_primary}
                    role = 'button'
                    onClick={()=>navigate(`/editTheme/${props.group.id}`)}
                    alt = ""></img>
                <img 
                    className = 'p-1' 
                    role = 'button'
                    style={{width:"36px"}}  
                    src = {CrossIcon}
                    onClick = {deleteHandler}
                    alt = ""></img>
            </div>
            
        </div>
    );
};

export default ThemesItem;