import React, {useEffect} from 'react';
import { getPlaylistGroups } from '../../actions/PlaylistActions'
import { useDispatch, useSelector } from 'react-redux'

const GroupHeader = (props) => {

    const dispatch = useDispatch()

    const groupList = useSelector(state => state.playlistGroups)
  
    const { playlistGroups} = groupList

    
    useEffect(()=>{
 
        dispatch(getPlaylistGroups())
    
      },[dispatch])
    
    return (
        <div className = 'w-100 my-3 d-flex flex-row align-items-center justify-content-center flex-wrap'
        style={{gap:"0.5rem"}}>
            {
                playlistGroups ?
                playlistGroups.map(group =>
                    <div key = {group.id}
                        className = {group.id == props.activeGroup ? 
                            'd-flex align-items-center px-3 my-0 my-lg-1 bg-info text-primary py-1 rounded-pill groups-components' : 
                            'd-flex align-items-center px-3 my-0 my-lg-1 text-info py-1 groups-components'}
                            role = "button"
                        onClick={()=>{
                            props.setActiveGroup(group.id); 
                            props.setSearchParams({ ...Object.fromEntries([...props.searchParams]), g:group.id})
                            localStorage.setItem('group_id', group.id)}}>
                        {group.title}
                    </div>
                ) : ''
            }
        </div>
    );
};

export default GroupHeader;