import React, {useEffect, useState} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { reset_password_confirm } from '../../actions/AuthActions';
import { useDispatch, useSelector } from 'react-redux';


function ResetPasswordConfirm(props) {

  const [newPassword, setNewPassword] = useState('')
  const [reNewPassword, setReNewPassword] = useState('')
  const [infoMessage, setInfoMessage] = useState('')
  const [confirmMessage, setConfirmMessage] = useState('')
  const navigate = useNavigate()
  const {uid, token} = useParams()

  const dispatch = useDispatch()

  const { resetConfirmMessage } = useSelector(state=>state.reset_password_confirm)

  useEffect(()=>{
    if(resetConfirmMessage){
      setConfirmMessage(resetConfirmMessage)
    }
  },[resetConfirmMessage])

  const submitHandler = () => {
    if (!newPassword || newPassword.length<8){
      setInfoMessage("Please, provide password at least 8 symbols")
    }

    if(newPassword === reNewPassword){
      dispatch(reset_password_confirm(uid, token, newPassword, reNewPassword))
    } else {
        setInfoMessage('Passwords do not match')
    }

  }


  return(
    <div className = "container-fluid min-vh-100 d-flex flex-column align-items-center justify-content-center"
        style={{background:"linear-gradient(#051932, #07294C)", padding:0, margin:0, height:"100vh"}}>
      <div className='container d-flex flex-column align-items-center justify-content-center'
        style={{gap:"1.5rem"}}>
        {
          confirmMessage && confirmMessage.length ?
          <>
            <div className = "text-info p-3 fs-4 text-center">{confirmMessage}</div>
              <button className = "col-10 col-md-8 col-lg-4 text-primary bg-info fw-bold border-0 rounded py-2"
              onClick={()=>navigate('/login')}
            >Sign In</button>
          </>
          :
          <>
            <div className = "text-info fs-2 fw-light">Reset Password</div>
            {infoMessage && <div className = "col-10 col-md-8 col-lg-4 text-info text-center">{infoMessage}</div>}
            <input className = "col-10 col-md-8 col-lg-4 p-2 border-0 rounded input"
              type="password"
              value = {newPassword}
              placeholder="password"
              name = "forgetPassword"
              autocomplete="off"
              onChange = {(e) => setNewPassword(e.target.value)} />

            <input className = "col-10 col-md-8 col-lg-4 p-2 border-0 rounded input"
              type="password"
              value = {reNewPassword}
              placeholder="confirm password"
              name = "forgetPasswordConfirm"
              autocomplete="off"
              onChange = {(e) => setReNewPassword(e.target.value)} />

            <button className = "col-10 col-md-8 col-lg-4 text-primary bg-info fw-bold border-0 rounded py-2"  onClick={submitHandler}>Reset</button>
          </>
        }
      </div>
    </div>
  )

      }

export default ResetPasswordConfirm;
