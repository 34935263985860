import React, {useState} from 'react';
import HomeIcon from './adminIcons/Home.svg';
import VideoIcon from './adminIcons/Videos.svg'
import PlaylistIcon from './adminIcons/Playlists.svg'
import ThemesIcon from './adminIcons/Themes.svg'
import HomeIconActive from './adminIcons/Home_active.svg';
import VideoIconActive from './adminIcons/Videos_active.svg'
import PlaylistIconActive from './adminIcons/Playlists_active.svg'
import ThemesIconActive from './adminIcons/Themes_active.svg'
import { useNavigate } from 'react-router-dom';

const AdminMenu = (props) => {

    const [activeTab, setActiveTab] = useState(1)

    const navigate = useNavigate()

    const video_id = localStorage.getItem('video_id') ? localStorage.getItem('video_id') : 827

    const playlist_id = localStorage.getItem('playlist_id') ? localStorage.getItem('playlist_id') : 10

    const group_id = localStorage.getItem('group_id') ? localStorage.getItem('group_id') : 4

    const clickHandler = (route, index) => {
        if(props.changes){
            var answer = window.confirm("You may loose the changes you made");
            if(answer){
                props.setChanges(false)
                navigate(route)
                setActiveTab(index)
            }
        } else {
            navigate(route)
            setActiveTab(index)
        }
    }

    return (
        <div className = 'position-relative'>
            <div className = 'min-vh-100 p-2 bg-secondary position-fixed' style={{ width: "15vw"}}>
                <div className = 'text-center fs-1 fw-bold text-info pt-2 pb-3'
                    role = 'button'
                    onClick={()=>navigate(`/home?v=${video_id}&p=${playlist_id}&g=${group_id}`)}
                >CU
                    <span className='fw-light text-white'>TV</span>
                </div>
                <ul className = 'm-0 p-0'>
                    <li className = {
                            activeTab === 1 ?
                            'position-relative px-3 py-3 bg-info rounded text-secondary':
                            'position-relative px-3 py-3 bg-secondary rounded text-info'
                        } 
                        style={{listStyle:'none'}} 
                        onClick = {()=>clickHandler('/main', 1) }
                        role='button'>
                        <div className = 'position-relative h-100 w-100 fw-bold fs-3 d-flex align-items-center'>   
                            <img src = {activeTab === 1 ? HomeIconActive : HomeIcon}></img>
                            <span className = 'ps-3 pt-1 link-name'>MY PAGE</span>
                        </div>
                    </li>
                    <li className = {
                        activeTab === 2 ?
                            'position-relative px-3 py-3 bg-info rounded text-secondary':
                            'position-relative px-3 py-3 bg-secondary rounded text-info'
                        }
                        style={{listStyle:'none'}} 
                        onClick = {()=>clickHandler('/adminVideos', 2)}
                        role='button'>
                        <div className = 'fw-bold fs-3 d-flex align-items-center'>   
                            <img src = {activeTab === 2 ? VideoIconActive : VideoIcon}></img>
                            <span className = 'ps-3 link-name'>VIDEOS</span>
                        </div>
                    </li>
                    <li className = {activeTab === 3 ?
                            'position-relative px-3 py-3 bg-info rounded text-secondary':
                            'position-relative px-3 py-3 bg-secondary rounded text-info'
                        }
                        style={{listStyle:'none'}} 
                        onClick = {()=>clickHandler('/adminPlaylists', 3)}
                        role='button'>
                        <div className = 'fw-bold fs-3 d-flex align-items-center'>   
                            <img src = {activeTab === 3 ? PlaylistIconActive : PlaylistIcon}></img>
                            <span className = 'ps-3 link-name'>PLAYLISTS</span>
                        </div>
                    </li>
                    <li className = {activeTab === 4 ?
                            'position-relative px-3 py-3 bg-info rounded text-secondary':
                            'position-relative px-3 py-3 bg-secondary rounded text-info'
                        }
                            style={{listStyle:'none'}} 
                            onClick = {()=>clickHandler('/themes', 4)}
                            role='button'>
                        <div className = 'fw-bold fs-3 d-flex align-items-center'>   
                            <img src = {activeTab === 4 ? ThemesIconActive : ThemesIcon}></img>
                            <span className = 'ps-3 link-name'>THEMES</span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default AdminMenu;