export const WATERMARK_REQUEST = 'WATERMARK_REQUEST'
export const WATERMARK_SUCCESS = 'WATERMARK_SUCCESS'
export const WATERMARK_FAIL = 'WATERMARK_FAIL'

export const FOOTPRINT_REQUEST = 'FOOTPRINT_REQUEST'
export const FOOTPRINT_SUCCESS = 'FOOTPRINT_SUCCESS'
export const FOOTPRINT_FAIL = 'FOOTPRINT_FAIL'

export const ANALYZE_INFO_REQUEST = 'ANALYZE_INFO_REQUEST'
export const ANALYZE_INFO_SUCCESS = 'ANALYZE_INFO_SUCCESS'
export const ANALYZE_INFO_FAIL = 'ANALYZE_INFO_FAIL'
