import React, {useState} from 'react';
import DeleteIcon from '../adminIcons/DeleteIcon.svg'
import EditIcon from '../adminIcons/EditIcon.svg'
import PlusIcon from '../adminIcons/PlusIcon.svg'
import { deleteVideoFromPlaylistForAdmin } from '../../actions/AdminPlaylistsActions';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import Modal from '../../app-main-content/Modal'
import useWindowDimentions from '../../app-main-content/useWindowDimentions';
import AddToPlaylist from '../AdminVideos/AddToPlaylist';

const VideoItem = (props) => {

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const [right, setRight] = useState(0)

    const [top, setTop] = useState(0)

    const {width, height} = useWindowDimentions()

    const [addActive, setAddActive] = useState(false)

    const deleteHandler = (video) => {
        var answer = window.confirm("Do you really want to delete video from playlist?");
        if (answer) {
            dispatch(deleteVideoFromPlaylistForAdmin(video.id, props.playlist_id, video.position))
        }
    }

    const get_members = (data) =>{

        let members = '' 
        
        if (data.artists && data.artists.length){
            members = data.artists
        }

        if (data.composers && data.composers.length){
            let c = data.composers.split(',')
            for (let i in c){
                if (!members.includes(c[i])){
                    members += ', '+ c[i]
                }
            }    
        }   
        return members
    }

    const { attributes, listeners, setNodeRef, transform, transition, isDragging  } = useSortable(
        {id: props.video.position})

    const style = {
        transition,
        transform: CSS.Transform.toString(transform),
        gap:"10px"
    }

    const addClickHandler = (e) => {
        setRight(width-e.clientX+5)
        setTop(e.clientY)
        setAddActive(true)
    }

    const editVideoHandler = () => {
        if(props.changes){
            var answer = window.confirm("You may loose the changes you made");
            if(answer){
                props.setChanges(false)
                navigate(`/editVideo/${props.video.id}`)
            }
        } else {
            navigate(`/editVideo/${props.video.id}`)
        }
    }


    return (
        <div className=' w-100 bg-warning border-bottom border-success d-flex align-items-center p-2'
            id={props.video.position}
            ref = {setNodeRef}
            {...attributes}
            {...listeners}
            style = {style}
        >
            <div className='bg-success border border-secondary text-info text-center rounded p-1'
                style = {{width:"5%"}}>
                {props.video.position}
            </div>
            <img 
                src = {`https://img.cugate.com/?o=CUTV_VIDEO&i=${props.video.id}&s=300`}
                alt = ""
                className='border border-secondary'
                style={{aspectRatio:"16/9", width:"15%"}}
            />
            <div className = "overflow-hidden" style={{width:"80%"}}>
                <div className = "text-white overflow-hidden">{props.video.title}</div>
                <div className = "text-info overflow-hidden">{get_members(props.video)}</div>
                <div className='d-flex'
                    style={{gap:"10px"}}>
                    <img 
                        src = {EditIcon} alt = "" 
                        role='button'
                        onClick={()=>editVideoHandler()}/>
                    <img 
                        src = {PlusIcon} alt = "" 
                        role='button'
                        onClick={addClickHandler}/>
                    <img 
                        src = {DeleteIcon} alt = "" 
                        role='button'
                        onClick={()=>deleteHandler(props.video)}
                    />
                </div>
            </div>
            <Modal 
                trigger = {addActive}
                setTrigger = {setAddActive}
                top = {top}
                right = {right}
                content = {
                    <AddToPlaylist
                        setIsActive = {setAddActive}
                        video = {props.video}
                        pl = {props.playlist_id}
                    /> 
                }
            /> 
        </div>
    );
};

export default VideoItem;