import {
    GET_GROUPS_FOR_ADMIN_REQUEST,
    GET_GROUPS_FOR_ADMIN_SUCCESS,
    GET_GROUPS_FOR_ADMIN_FAIL,

    ADD_GROUP_REQUEST,
    ADD_GROUP_SUCCESS,
    ADD_GROUP_FAIL,

    DELETE_GROUP_REQUEST,
    DELETE_GROUP_SUCCESS,
    DELETE_GROUP_FAIL,

    GET_GROUP_INFO_REQUEST,
    GET_GROUP_INFO_SUCCESS,
    GET_GROUP_INFO_FAIL,

    DELETE_GROUP_PLAYLIST_REQUEST,
    DELETE_GROUP_PLAYLIST_SUCCESS,
    DELETE_GROUP_PLAYLIST_FAIL,

  } from '../constants/GroupsConstants'

const  initialAdminGroups = {
    adminGroups : [],
    loadingAdminGroups: false,
    adminGroupsError : null
  }

  export const adminGroupsReducer = (state = { initialAdminGroups }, action) => {
    switch (action.type){
      case GET_GROUPS_FOR_ADMIN_REQUEST:
        return { 
          loadingAdminGroups: true,
          ...state
        }
  
      case GET_GROUPS_FOR_ADMIN_SUCCESS:
        return { 
          loadingAdminGroups: false, 
          adminGroups: action.payload 
        }


      case ADD_GROUP_REQUEST:
        return{
          ...state
        }

      case ADD_GROUP_SUCCESS:
        return{
          ...state,
          adminGroups: [...state.adminGroups, action.payload]
        }

      case ADD_GROUP_FAIL:
        return {
          ...state
        }

      case DELETE_GROUP_REQUEST:
        return{
          ...state
        }

      case DELETE_GROUP_SUCCESS:
        let grs = state.adminGroups.filter(gr=>gr.id!==action.group_id)
        grs.forEach((group)=>{
          if (group.position > action.position){
            group.position -= 1 
          }
        })
        state.adminGroups = grs
        return { 
          ...state,
          groupInfo: [...state.adminGroups],
        }

      case DELETE_GROUP_FAIL:
        return {
          ...state
        }

      case GET_GROUPS_FOR_ADMIN_FAIL:
        return { 
          loadingAdminGroups: false, 
          error: action.payload }
  
      default:
        return state
    }
  }


  export const groupInfoReducer = (state = { groupInfo: {} }, action) => {
    switch (action.type){
      case GET_GROUP_INFO_REQUEST:
        return { 
          loadingGroupInfo: true,
          ...state
        }
  
      case GET_GROUP_INFO_SUCCESS:
        return { 
          loadingGroupInfo: false, 
          groupInfo: action.payload 
        }

      case GET_GROUP_INFO_FAIL:
        return { 
          loadingGroupInfo: false, 
          error: action.payload }


      case DELETE_GROUP_PLAYLIST_REQUEST:
        return { 
          ...state
        }
  
      case DELETE_GROUP_PLAYLIST_SUCCESS:
        let pls = state.groupInfo.playlists.filter(pl=>pl.id!==action.playlist_id)
        pls.forEach((playlist)=>{
          if (playlist.position > action.position){
            playlist.position -= 1 
          }
        })
        state.groupInfo.playlists = pls
        return { 
          ...state,
          groupInfo: [...state.groupInfo],
        }

      case DELETE_GROUP_PLAYLIST_FAIL:
        return { 
          error: action.payload,
          ...state
        }
  
      default:
        return state
    }
  }