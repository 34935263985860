
export const GET_GROUPS_FOR_ADMIN_REQUEST = 'GET_GROUPS_FOR_ADMIN_REQUEST'
export const GET_GROUPS_FOR_ADMIN_SUCCESS = 'GET_GROUPS_FOR_ADMIN_SUCCESS'
export const GET_GROUPS_FOR_ADMIN_FAIL = 'GET_GROUPS_FOR_ADMIN_FAIL'

export const ADD_GROUP_REQUEST = 'ADD_GROUP_REQUEST'
export const ADD_GROUP_SUCCESS = 'ADD_GROUP_SUCCESS'
export const ADD_GROUP_FAIL = 'ADD_GROUP_FAIL'

export const EDIT_GROUP_REQUEST = 'EDIT_GROUP_REQUEST'
export const EDIT_GROUP_SUCCESS = 'EDIT_GROUP_SUCCESS'
export const EDIT_GROUP_FAIL = 'EDIT_GROUP_FAIL'

export const DELETE_GROUP_REQUEST = 'DELETE_GROUP_REQUEST'
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS'
export const DELETE_GROUP_FAIL = 'DELETE_GROUP_FAIL'

export const CHANGE_GROUP_STATUS_REQUEST = 'CHANGE_GROUP_STATUS_REQUEST'
export const CHANGE_GROUP_STATUS_SUCCESS = 'CHANGE_GROUP_STATUS_SUCCESS'
export const CHANGE_GROUP_STATUS_FAIL = 'CHANGE_GROUP_STATUS_FAIL'

export const ARRANGE_GROUPS_REQUEST = 'ARRANGE_GROUPS_REQUEST'
export const ARRANGE_GROUPS_SUCCESS = 'ARRANGE_GROUPS_SUCCESS'
export const ARRANGE_GROUPS_FAIL = 'ARRANGE_GROUPS_FAIL'

export const GET_GROUP_INFO_REQUEST = 'GET_GROUP_INFO_REQUEST'
export const GET_GROUP_INFO_SUCCESS = 'GET_GROUP_INFO_SUCCESS'
export const GET_GROUP_INFO_FAIL = 'GET_GROUP_INFO_FAIL'

export const DELETE_GROUP_PLAYLIST_REQUEST = 'DELETE_GROUP_PLAYLIST_REQUEST'
export const DELETE_GROUP_PLAYLIST_SUCCESS = 'DELETE_GROUP_PLAYLIST_SUCCESS'
export const DELETE_GROUP_PLAYLIST_FAIL = 'DELETE_GROUP_PLAYLIST_FAIL'
