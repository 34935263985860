import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getGroupsForAdmin } from '../../actions/GroupsActions';
import ThemesItem from './ThemesItem';
import addIcon from '../adminIcons/add.svg'
import Modal from '../../app-main-content/Modal'
import useWindowDimentions from '../../app-main-content/useWindowDimentions';
import AddTheme from './AddTheme';
import {
    DndContext, 
    closestCorners,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core"
import {
    arrayMove,
    SortableContext,
    verticalListSortingStrategy,
    sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import { arrangeGroups } from '../../actions/GroupsActions';



const ThemesPage = () => {

    const dispatch = useDispatch()

    const [items, setItems] = useState()

    const [addActive, setAddActive] = useState()

    const { adminGroups, loadingAdminGroups } = useSelector(state=>state.adminGroups)

    const {width, height} = useWindowDimentions()

    useEffect(()=>{
        dispatch(getGroupsForAdmin())
    },[])


    useEffect(()=>{
        if(adminGroups && adminGroups.length){
            setItems(adminGroups)
        }
    },[adminGroups])

    const sensors = useSensors(
        useSensor(MouseSensor, {
        // Require the mouse to move by 10 pixels before activating
        activationConstraint: {
            distance: 10,
        },
        }),
        useSensor(TouchSensor, {
        // Press delay of 250ms, with tolerance of 5px of movement
        activationConstraint: {
            delay: 250,
            tolerance: 5,
        },
        }),
        useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const handleDragEnd = (event) => {
        const {active, over} = event;
        if (active.id !== over.id) {
          setItems((items) => {
            const oldIndex = items.map(item=>item.position).indexOf(active.id);
            const newIndex = items.map(item=>item.position).indexOf(over.id);
            if (active.id>over.id){
                items.forEach(item => {
                    if(item.position < active.id && item.position >= over.id){
                        item.position += 1
                    } else if (item.position === active.id){
                        item.position = over.id
                    }
                });
            } else if (active.id<over.id){
                items.forEach(item => {
                    if(item.position > active.id && item.position <= over.id){
                        item.position -= 1
                    } else if (item.position === active.id){
                        item.position = over.id
                    }
                });
            } 
            return arrayMove(items, oldIndex, newIndex);
          });
          dispatch(arrangeGroups(active.id, over.id))
        }
    }


    
    return (
        <div className = 'col-9 bg-success p-4 my-5 me-5 rounded col-9' style={{marginLeft:"16vw", minHeight:"100vh"}}>
            <div className = 'pb-4 d-flex justify-content-between align-items-center pe-5'>
                <div className = "fw-bold fs-7 text-info" 
                    role='button' 
                >THEMES</div>
                <div className = 'bg-secondary rounded-pill px-3 d-flex align-items-center'
                    style={{height:"40px"}}
                    role='button'
                    onClick = {()=>{setAddActive(true)}}
                    >
                    <img src = {addIcon}/>
                    <div className='text-white ps-1 text-nowrap'
                    >Add Theme</div>
                </div>
            </div>

                <div className = "my-3 bg-info w-100" style={{height:"2px"}}></div>

                <div className = 'p-3 d-flex'>
                    <div className = 'text-info col-5 text-center'>Title</div>
                    <div className = 'text-info col-2 text-center'>Position</div>
                    <div className = 'text-info col-2 text-center'>Playlists</div>
                    <div className = 'text-info col-2 text-center'>Status</div>
                </div>
                {
                    loadingAdminGroups ? 
                    <div className='py-5 text-info fs-2'>Loading...</div> : 
                    <DndContext 
                        sensors={sensors}
                        collisionDetection={closestCorners}
                        onDragEnd={handleDragEnd}
                        >
                        {
                            items && items.length ? 
                            <SortableContext items = {items.map(group=>group.position)} strategy={verticalListSortingStrategy}>
                                {
                                    items.map((group, index)=>(
                                        <ThemesItem 
                                            key = {index}
                                            group = {group}
                                        />
                                    )) 
                                }
                            </SortableContext> :""
                        }
                    </DndContext>
                }
                <Modal 
                    trigger = {addActive}
                    setTrigger = {setAddActive}
                    top = {0}
                    right = {0}
                    content = {
                        <AddTheme
                            setIsActive = {setAddActive}
                            width = {width}
                            height = {height}
                        /> 
                    }
                /> 
        </div>
    );
};

export default ThemesPage;