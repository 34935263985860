import React, {useEffect} from 'react';
import Slider from 'react-slick'
import {useNavigate} from 'react-router-dom'


function SliderOfGroup(props) {

  const navigate = useNavigate()


    const itemClickHandler = (item) => {
        localStorage.setItem('playlist_id', item.id)
        localStorage.setItem('video_id', item.video_id)
        localStorage.setItem('group_id', props.group.id)
        if(props.user) {
          navigate(encodeURI(`/home?g=${props.group.id}&p=${item.id}&v=${item.video_id}`))
        } else {
          navigate('/login')
        }
      }

      useEffect(()=>{
        props.group.playlists && props.group.playlists.length && props.slideRef?.slickGoTo(0)
    },[props.group.playlists])

    const settings = {
        dots: false,
        infinite: false,
        arrows: false,
        speed: 500,
        slidesToScroll: 3,
        inisialSlide: 0 ,
        mobileFirst:true,
        responsive: [
            {
              breakpoint: 0,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
                initialSlide: 0,
              }
            },
            {
              breakpoint: 800,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
                initialSlide: 0, 
                infinite: false,
              }
            },
            {
              breakpoint: 1120,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 3, 
                initialSlide: 0,
                infinite: false,
              }
            },
            {
                breakpoint: 1400,
                settings: {
                  slidesToShow: 6,
                  slidesToScroll: 3, 
                  initialSlide: 0,
                  infinite: false
                }
              },
              {
                breakpoint: 9000,
                settings: {
                  slidesToShow: 6,
                  slidesToScroll: 3, 
                  initialSlide: 0,
                  infinite: false
                }
              }
          ]
      };


      const viewAllHandler = () => {
        localStorage.setItem('group_id', props.group.id)
        localStorage.setItem('playlist_id', props.group.playlists[0].id)
        localStorage.setItem('video_id', props.group.playlists[0].video_id)
        if(props.user){
          navigate(encodeURI(`/home?g=${props.group.id}&p=${props.group.playlists[0].id}&v=${props.group.playlists[0].video_id}`))
        } else {
          navigate('/login')
        }
      }

    return (
        <div className="col-12" style = {{marginTop:"2.5vw"}}>
            <div className="d-flex justify-content-between align-items-center" style = {{marginBottom:"0.5vw", fontSize:"1.25rem", color:'white'}}>
                <span className="">{props.title}</span>
                <div className="viewall-link" onClick={()=>viewAllHandler()} style={{cursor:"pointer"}}>View all &raquo;</div>
            </div>
            <Slider {...settings} ref={props.setSlideRef} slickGoTo>
                { props.group.playlists && props.group.playlists.length ?
                    props.group.playlists.map((item, index) => (
                        <div className='px-2' key={index} onClick = {()=>{itemClickHandler(item)}}>
                            <div style = {{cursor:"pointer"}} >
                                <img src = {`https://img.cugate.com/?o=CUTV_PLAYLIST&i=${item.id}&s=300`}
                                    alt = "aaaaaa" 
                                    style = {{
                                        objectFit: 'cover',
                                        width:"99%", 
                                        height: `100%`,
                                    }}
                                    onError = {({ currentTarget }) => {
                                        currentTarget.onerror = null // prevents looping
                                        // currentTarget.src=Default
                                        }}    
                                />
                            </div>
                        </div>
                    )) : ''
                }
            </Slider>

        </div>
    );
}

export default SliderOfGroup;
