import React from 'react'

const VideoInfo = ({data}) => {

  const get_members = (data) =>{
    let members = ''

    if (data.artists && data.artists.length){
      members = data.artists
  }

  if (data.composers && data.composers.length){
      let c = data.composers.split(',')
      for (let i in c){
          if (!members.includes(c[i])){
              members += ', '+ c[i]
          }
      }    
  }
    return members
}

  return (
    <div className = "w-100 d-flex flex-row align-items-center pt-1 pt-lg-3">
      <div className = "bg-info d-none align-self-start d-lg-block text-primary text-nowrap rounded-circle fw-bold p-2">CU</div>
      <div
          className='ms-0 w-100 ms-lg-3 text-wrap px-1 d-flex flex-column'
          >
        <h6
          className='text-white fw-bold overflow-hidden m-0'
          style={{height:"auto"}}
          title={data.title}>{data.title}</h6>
          {get_members(data).length ? 
        <h6
          className='text-info overflow-hidden'
          title={get_members(data)}>{get_members(data)}</h6> : ""}
      </div>
    </div>
  )
}
export default VideoInfo;
