import React, {useState} from 'react'
import Filter from './Filter'
import cuViewTV from '../icons/CuViewTV.svg'
import { useNavigate } from 'react-router-dom'
import person from '../icons/person.svg'
import SearchContent from './SearchContent'
import filterIcon from '../icons/filterIcon.svg'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../actions/AuthActions';
import BackArrow from '../icons/BackArrow.svg'
import { Tooltip } from 'react-tooltip'


function Header(props) {

  const [filterActive, setFilterActive] = useState(false)
  const [profileActive, setProfileActive] = useState(false)
  const [searchActive, setSearchActive] = useState(false)
  const { user } = useSelector(state=>state.user)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const logoutHandler = () =>{
    dispatch(logout())
    navigate('/')
  }

  const goHomeClickHandler = () => {
    let video_id = localStorage.getItem('video_id') ?  localStorage.getItem('video_id') : 403
    let playlist_id = localStorage.getItem('playlist_id') ?  localStorage.getItem('playlist_id') : 54
    let group_id = localStorage.getItem('group_id') ?  localStorage.getItem('group_id') : 4
    navigate(encodeURI(`/home?g=${group_id}&p=${playlist_id}&v=${video_id}`))
  }

  return (
    <>
      <div className="w-100 bg-secondary d-flex justify-content-center">
        <div className = "col-12 col-lg-10 px-2 px-lg-5 py-2 mb-3 mb-lg-0 py-lg-3 d-flex flex-column flex-lg-row flex-md-column flex-sm-column justify-content-between align-items-center">
          <div className = "col-7 py-2 py-lg-1 col-lg-2 col-md-4 d-flex justify-content-center">
            {
              // props.showBackArrow && 
              <img src = {BackArrow} role='button' className = "pe-3" onClick={()=>navigate(-1)}/>
            }
            <img role="button"  
              src={cuViewTV}  alt="CuviewTV TV logo" 
              style={{height:"2.5rem"}}
              onClick={()=>goHomeClickHandler()}/>
          </div>
          <div className="col-12 h-75 col-lg-8 d-flex align-items-center justify-content-end"
          >
            <SearchContent
              searchActive = {searchActive}
              setSearchActive = {setSearchActive}
              setFilterActive = {setFilterActive}
            />
            <div className = 'col-3 col-lg-2 d-flex flex-column justify-content-start position-relative'>
              <div className='col-12 d-flex p-1' style={{height:"2.5rem"}}>
                <div className='col-5 h-100 p-1 d-flex justify-content-center' data-tooltip-id="my-tooltip" data-tooltip-content="For More Search Options Click Here!">
                  <img src = {filterIcon} 
                    className = ""
                    role='button'
                    onClick={()=>setFilterActive(!filterActive)}
                    alt = ""/>  
                    {
                      !filterActive && !searchActive ?
                      <>
                          <Tooltip id = 'new-tooltip' className='bg-info'/>
                          <Tooltip id="my-tooltip" className='bg-info'/>
                      </> : ""
                    }
                </div>
                  {
                    user && user.first_name ? 
                    <div className='col-8 col-lg-11 d-flex flex-column flex-md-row h-100 justify-content-center justify-content-md-start align-items-center'
                      onClick={()=>setProfileActive(!profileActive)} >
                      <img
                        src = {person}
                        alt="person icon" 
                        role='button'
                      />
                      <div  
                        alt="person icon" 
                        role='button'
                        className = "fs-2 ps-2 d-none d-md-block text-info overflow-hidden text-nowrap" 
                      >{user.first_name}</div>
                    </div> : ""
                    }
                  </div>
              {
                profileActive && 
                <div 
                    className = "col-12 px-1 px-lg-2 py-1 mt-5 ms-0 ms-lg-5 bg-white position-absolute text-center rounded" 
                    style={{zIndex:"999"}}
                    onMouseLeave = {()=> setProfileActive(false)}>
                    <div className = "px-3 py-2 w-100 rounded color-secondary profile-menu-object" 
                      onClick = { logoutHandler } >Logout
                    </div>
                    <div className = "px-1 fs-5 fs-md-6 py-2 w-100 text-nowrap rounded color-secondary profile-menu-object" 
                        onClick={()=>{ props.myPlaylistsRef.current && props.myPlaylistsRef.current.scrollIntoView({ behavior: "smooth" })}}
                       >My Playlists
                    </div>
                    {
                      user && user.is_staff ?
                      <div className = "px-1 fs-5 fs-md-6 py-2 w-100 text-nowrap rounded color-secondary profile-menu-object" 
                          onClick={()=>navigate(`/main`)}
                        >Admin Page
                      </div> : ""
                    }
                </div>
              }
            </div>
            </div>
          </div>
        </div>
        {
          filterActive &&
          <Filter />
        }

    </>
  );
}

export default Header;