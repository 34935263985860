import React, {useEffect, useState}  from 'react';
import VideosHeader from './VideosHeader';
import VideoList from './VideoList';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import { getVideosForAdmin, executeAdminFilter, executeAdminSearch, getVideosLength, getVideosOff } from '../../actions/videoActions';


const AdminVideos = () => {

    const dispatch = useDispatch()

    const [page, setPage] = useState(1)

    const [filterList, setFilterList] = useState([])
    const [searchValue, setSearchValue] = useState("")

    const [videoList, setVideoList] = useState([])

    const { adminVideos, index, videosLength, adminVideosLoading } = useSelector(state=>state.adminVideos)

    const handlePageClick = (event) => {
        setPage(event.selected+1)
    }

    useEffect(()=>{
        if(index){
            if (index === 1){
                dispatch(getVideosForAdmin(page, 1))
            } else if (index === 2 && filterList.length){
                dispatch(executeAdminFilter(filterList, page, 2))
            } else if (index === 3 && searchValue.length){
                dispatch(executeAdminSearch(searchValue, page, 3))
            } else if (index === 4 ){
                dispatch(getVideosOff(page, 4))
            }
        } else {
            dispatch(getVideosLength())
            dispatch(getVideosForAdmin(1, 1))
        }

    },[page])

    return (
        <div className = 'px-5 py-5 col-10' style={{marginLeft:'15vw', minHeight:"100vh"}}>
            <VideosHeader
                filterList = {filterList}
                setFilterList = {setFilterList}
                searchValue = {searchValue}
                setSearchValue = {setSearchValue}
                setPage = {setPage}
                videoList = {videoList}
            />
            {
                adminVideosLoading ? 
                <div className='py-5 text-info fs-2'>Loading...</div> : 
                <VideoList
                    videos = {adminVideos}
                    index = {index}
                    setVideoList = {setVideoList}
                    videoList = {videoList}
                />
            }
            {
                videosLength &&  
                <div className = 'd-flex justify-content-end bg-warning pb-5'>
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel=">>"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        pageCount={Math.ceil(videosLength/10)}
                        previousLabel="<<"
                        renderOnZeroPageCount={null}
                        marginPagesDisplayed = {1}
                        containerClassName = "d-flex py-2 col-4 justify-content-end"
                        pageClassName = 'text-info col-1 py-1 rounded bg-success border border-secondary list-unstyled m-1 text-center'
                        previousClassName = 'text-info col-1 py-1 rounded bg-success border border-secondary list-unstyled m-1 text-center'
                        previousLinkClassName = 'text-info col-1 py-1 rounded bg-success text-decoration-none m-1 text-center'
                        nextClassName = 'text-info col-1 py-1 rounded bg-success border border-secondary list-unstyled m-1 text-center'
                        nextLinkClassName = 'text-info col-1 py-1 rounded bg-success text-decoration-none m-1 text-center'
                        breakClassName = 'col-1 text-info list-unstyled d-flex align-items-end justify-content-center fs-2'
                        activeClassName = 'bg-info text-white'
                    />
                </div> 
            }
        </div>
    );
};

export default AdminVideos;