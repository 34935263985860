import React, {useState} from 'react'
import GroupHeader from './GroupHeader';
import PlaylistGroup from './PlaylistGroup';

const GroupsComponent = (props) =>{

    let group_id = parseInt(props.searchParams.get('g')) ? parseInt(props.searchParams.get('g')) : localStorage.getItem('group_id') ? localStorage.getItem('group_id') : 4

    const [activeGroup, setActiveGroup] = useState(group_id)

    return(
        <div className = 'container-fluid bg-primary' style={{backgroundColor: '#07294c'}}>
            <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                <GroupHeader
                    activeGroup = {activeGroup}
                    setActiveGroup = {setActiveGroup}
                    searchParams = {props.searchParams}
                    setSearchParams = {props.setSearchParams}
                />
                </div>
                <PlaylistGroup
                    activeGroup = {activeGroup}
                    setActiveGroup = {setActiveGroup}
                    displayCount={props.displayCount}
                    sCWHeight={props.sCWHeight}
                    slideClickHandler={props.slideClickHandler}
                    showAllPath={props.showAllPath}
                />
        </div>
    )
}

export default GroupsComponent;