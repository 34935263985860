import React, {useRef, useEffect, useState} from 'react'
import SidePlaylist from './SidePlaylist'
import VideoNameAndActions from './VideoNameAndActions'
import { useSelector } from 'react-redux'
import PlayerHeader from './PlayerHeader'
import AnalyzeButton from './AnalyzeButton'
import useWindowDimentions from '../useWindowDimentions'
import CustomPlayer from './CustomPlayer'


export default function VideoPlayer(props) {
  
  const { playlistVideos } = useSelector(state => state.userPlaylists)
  const videoPlayerRef = useRef()
  const [analyzeBottom, setAnalyzeBottom] = useState(false)
  const {width, height} = useWindowDimentions()

  useEffect(()=>{
    if(width < 990){
      setAnalyzeBottom(true)
    } else {
      setAnalyzeBottom(false)
    }
  },[width])
  

  useEffect(()=>{
    videoPlayerRef.current && videoPlayerRef.current.scrollIntoView({ behavior: "smooth" })
  },[playlistVideos])



  return (
    <div className = 'container-fluid position-relative bg-primary pb-5 d-flex flex-column justify-content-center align-items-center px-1 px-lg-5' ref = {videoPlayerRef}>
      <PlayerHeader
        setVideo = {props.setVideo}
        searchParams = {props.searchParams}
        setSearchParams = {props.setSearchParams}
      />
      <div className = "col-12 position-relative col-lg-11 d-flex flex-column flex-lg-row align-items-start px-1 justify-content-center justify-content-lg-between">
        {
          !analyzeBottom &&       
          <AnalyzeButton 
            handleRewind = {props.handleRewind}
            video = {props.video}
          />
        }
        <div className="col-12 col-lg-8 d-flex flex-column pe-0 pe-lg-4 "
        >
          <div className = 'w-100 d-flex mt-2 pb-4 pb-lg-0 position-relative d-flex flex-column'>
            { 
                <CustomPlayer   
                    playerRef = {props.playerRef}
                    setPlayerRef= {props.setPlayerRef}
                    video = {props.video}
                    setVideo = {props.setVideo}
                    searchParams = {props.searchParams}
                    setSearchParams = {props.setSearchParams}
                />
            
          }
          <div className = 'd-flex'>
          {
              analyzeBottom &&
              <AnalyzeButton 
                handleRewind = {props.handleRewind}
                video = {props.video}
            />
            }
              <VideoNameAndActions  
                video = {props.video}
              />
          </div>
          </div>
        </div>
        <div className = "col-12 col-lg-3 pt-3 pt-md-0">
          <SidePlaylist
            setVideo = {props.setVideo}
            searchParams = {props.searchParams}
            setSearchParams = {props.setSearchParams}
          />
        </div>
      </div>
    </div>
  )

}

