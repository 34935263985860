import React, {useState, useEffect, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddVideoFilterComponent from './AddVideoFilterComponent';
import { getVideogenresForAdmin } from '../../actions/AdminFilterActions';
import Modal from '../../app-main-content/Modal'
import useWindowDimentions from '../../app-main-content/useWindowDimentions';
import AddVideoGenre from './AddVideoGenre';


const AdminVideoGenres = (props) => {

    const searchAdminVideoGenreRef = useRef()

    const dispatch = useDispatch()
  
    const {adminVideogenresLoading, adminVideogenres} = useSelector(state => state.adminVideogenres)

    const [videoGenreActive, setVideoGenreActive] = useState(false)

    const [videoGenreValue, setVideoGenreValue] = useState("")

    const [page, setPage] = useState(1)

    const [right, setRight] = useState(0)

    const [top, setTop] = useState(0)

    const [addActive, setAddActive] = useState(false)

    const {width, height} = useWindowDimentions()

    const handleScroll = event => {
        const {scrollTop, clientHeight, scrollHeight} = event.currentTarget;
        let h
        h = Math.round(scrollHeight - scrollTop)
        if(h === clientHeight || h+1 === clientHeight || h-1 === clientHeight){
          setPage(prev => prev + 1)
        }
      }

    useEffect(()=>{
        setPage(1)
        const identifer = setTimeout(()=>{
            if (videoGenreValue){
                dispatch(getVideogenresForAdmin(videoGenreValue, 1, 0))
            } else {
                dispatch(getVideogenresForAdmin(" ", 1, 0))
            }
        }, 500)  

        return () => { clearTimeout(identifer) }

    },[videoGenreValue])

    useEffect(()=>{
        if (videoGenreValue){
            dispatch(getVideogenresForAdmin(videoGenreValue, page, 1))
        } else {
            dispatch(getVideogenresForAdmin(" ", page, 1))
        }
    }, [page])

    useEffect(()=>{
        document.addEventListener("mousedown", (event) => {
            if (searchAdminVideoGenreRef == null || searchAdminVideoGenreRef.current == null || !searchAdminVideoGenreRef.current.contains(event.target)){
            setVideoGenreActive(false)
            }
        })
    }) 

    const chooseHandler = (videoGenre) => {
        if(!props.videoGenres.includes(videoGenre)){
            props.setVideoGenres(prev=>[...prev, videoGenre])
            props.setChanges(true)
        }
        setVideoGenreActive(false)
        setVideoGenreValue("")
    }

    const addClickHandler = (e) => {
        setRight(width-e.clientX+5)
        setTop(e.clientY)
        setAddActive(true)
    }

    return(
        <>
            <div className = "text-secondary fw-bold pb-2 pt-4 w-100">Video Genres*</div>
            <div className='d-flex w-100 justify-content-between align-items-end'>
                <AddVideoFilterComponent
                    data = {adminVideogenres}
                    setValue = {setVideoGenreValue}
                    setActive = {setVideoGenreActive}
                    value = {videoGenreValue}
                    isActive = {videoGenreActive}
                    chooseHandler = {chooseHandler}
                    componentRef = {searchAdminVideoGenreRef}
                    handleScroll = {handleScroll}
                    loading = {adminVideogenresLoading}
                    chosenData = {props.videoGenres}
                    setChosenData = {props.setVideoGenres}
                    width = "90%"
                />
                <div className="py-2 px-1 bg-secondary rounded text-success text-nowrap"
                    role="button"
                    style={{fontWeight:600}}
                    onClick={(e)=>addClickHandler(e)}
                >add new</div>
            </div>
            <Modal 
                    trigger = {addActive}
                    setTrigger = {setAddActive}
                    top = {top}
                    right = {right}
                    content = {
                        <AddVideoGenre
                            setIsActive = {setAddActive}
                        /> 
                    }
                />   
        </>
    )

};

export default AdminVideoGenres;
