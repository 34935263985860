import React from 'react';
import LandingHeader from './LandingHeader'
import LandingFooter from './LandingFooter';
import GroupSlides from './GroupSlides';
import Banner from './Banner';
import './LandingStyles.css'
import { useSelector} from 'react-redux';

const LandingPage = () => {

    const {userLoading, user} = useSelector(state=>state.user)

    return (
        <div>
            <div id = 'landing_body'>
            <LandingHeader
                user = {user}
                userLoading = {userLoading}
            />
            <Banner
                user = {user}
            />
            <GroupSlides
                user = {user}
            />
            <LandingFooter/>
            </div>
        </div>
        
    );
}

export default LandingPage;