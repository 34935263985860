import React from 'react';
import Attach from '../adminIcons/Attach.svg'
import ReactPlayer from 'react-player/lazy'
import { editVideoThumbnail } from '../../actions/videoActions';
import { useDispatch } from 'react-redux';

const UploadForEdit = (props) => {

    const dispatch = useDispatch()

    const handleImage = (e) => {
        props.setImageFile(e.target.files[0])
        let u = URL.createObjectURL(e.target.files[0])
        props.setImageUrl(u)
        dispatch(editVideoThumbnail(props.video_id, e.target.files[0]))
    }

    return (
        <div className = "col-3 bg-success rounded p-2">
            {
                props.videoUrl && 
                <div className = "bg-warning p-2 mt-3 rounded">
                    <ReactPlayer
                        // ref = {}
                        className='react-player'
                        controls
                        url = {props.videoUrl}
                        // light = {`https://img.cugate.com/?o=CUTV_VIDEO&i=${12}&s=600`}
                        width="100%"
                        height='100%'
                    />
                </div> 
            }

            <div className = "bg-warning p-2 mt-3 rounded">
                <div className = "text-info pb-2">Change Thumbnail</div>
                {
                     props.imageURL ? 
                    <>
                        <img src = {props.imageURL} alt = ""
                            style = {{width:"100%", aspectRatio:"16/9"}}
                        />
                        <div className = "w-100 d-flex justify-content-between align-items-center mt-3">
                            {
                                props.imageFile && props.imageFile.name ? 
                                <div className = "text-info w-75 text-decoration-underline text-wrap overflow-hidden">{props.imageFile.name}</div> : ""
                            }

                            <input 
                                id = "reimage-input"
                                type="file" 
                                name="imageFile"
                                accept='image/jpeg, image/png, image/jpg'
                                onChange={handleImage}
                                style={{display:"none"}}
                                />
                                <label for="reimage-input">
                                    <div 
                                        className = "text-info py-2 px-3 ms-2 bg-success rounded"
                                        role='button'
                                    >Change</div>
                                </label>
                        </div>
                    </>
                    : 
                    <div className = "w-100 px-3 py-5 bg-warning rounded">
                        <div className = "w-100 py-5 d-flex bg-secondary justify-content-center">
                            <input 
                                id = "image-input"
                                type="file" 
                                name="imageFile"
                                accept='image/jpeg, image/png, image/jpg'
                                onChange={handleImage}
                                style={{display:"none"}}
                                />
                            <label for="image-input">
                                <img src = {Attach} alt = "" role="button"/>
                            </label>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default UploadForEdit;