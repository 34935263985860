import React, {useState, useEffect, useRef} from 'react'
import search from '../icons/search.svg'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getSearchSuggestions } from '../../actions/filterActions'


const SearchContent = (props) => {

  let searchRef = useRef()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchValue, setSearchValue] = useState("")
  const [val, setVal] = useState("")


  useEffect(()=>{
    if(localStorage.getItem("searchValue")){
      setSearchValue(localStorage.getItem("searchValue"))
      localStorage.removeItem("searchValue")
    }
  },[])


  const executeSearch = (value) => {
    // dispatch(getSearchResults(value, 1))
    navigate(`/search_results/${value}/`)
    props.setSearchActive(false)
}

useEffect(()=>{
  const identifer = setTimeout(()=>{
      if (searchValue){
        dispatch(getSearchSuggestions(searchValue))
        props.setSearchActive(true)
      } else {
        props.setSearchActive(false)
      }
  }, 500)  

  return () => { clearTimeout(identifer) }

},[val])


  const {searchSuggestions, loadingSuggestions} = useSelector(state=>state.searchSuggestions)

  // chooses a value from suggested results from currentSearch results
  const chooseHandler = (value) => {
    setSearchValue(value.title)
    executeSearch(value.title)
  }

  const handleKeyDown = (e) => {
    if(e.code === "Enter"){
      executeSearch(searchValue)
    }
  }

  useEffect(()=>{
    if(searchSuggestions && !searchSuggestions.length && !loadingSuggestions){
        props.setFilterActive(true)
    }
},[searchSuggestions])

  useEffect(()=>{
    document.addEventListener("mousedown", (event) => {
      if (searchRef == null || searchRef.current == null || !searchRef.current.contains(event.target)){
        props.setSearchActive(false)
      }
    })
  })

  return (
    <div className='col-9 col-lg-10 d-flex flex-column justify-content-end align-items-end search-wrapper'>
      <div className = "col-12 px-2 col-lg-9 position-relative d-flex justify-content-end align-items-center" 
        style={{userSelect:"none", height:"2.5rem"}} 
        ref = {searchRef}>
        <div className="w-100 h-100 w-lg-75 col-lg-10 bg-white d-flex py-1 rounded"
        >
          <input
            type="text"
            data-tooltip-id="new-tooltip"  data-tooltip-content="For more search options, press the filter button on the right!"
            className="col-11 fs-5 border-0 text-secondary search-input ps-3"
            placeholder="Search by title..."
            onKeyDown={handleKeyDown}
            onClick={()=>props.setSearchActive(true)}
            onChange={(e)=>{setSearchValue(e.target.value); setVal(e.target.value)}}
            value={searchValue}
          />
            <div className='col-1 d-flex justify-content-end pe-3' onClick={()=> executeSearch(searchValue)}>
              <img src={search} alt="search icon" role= "button" className='p-1' />
            </div>
            {
          props.searchActive ?
          loadingSuggestions ? <div className='w-100 w-lg-75 position-absolute mt-5 p-2 bg-white shadow rounded'>Loading...</div> :
          searchSuggestions && searchSuggestions.length ?
              <div className = "w-100 w-lg-75 position-absolute mt-5 p-2 bg-white shadow rounded" 
              style = {{color:"#333", zIndex:1000}}>
                {
                  searchSuggestions.map((result) => (
                      <div
                        className = "p-2 addSearchDropdown-item"
                        role = "button"
                        style={{transition:"all 0.2s"}}
                        key = {result.id}
                        onClick = {()=>chooseHandler(result)}
                      >{result.title}</div>
                  ))
                }
            </div> : <div className='w-100 w-lg-75 position-absolute mt-5 p-2 bg-white shadow rounded'>No Result, please try a different query</div> : ""
          }
        </div>
      </div>
    </div>
  )
}

export default SearchContent;
