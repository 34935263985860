import React, { useState, useEffect, useRef} from 'react';
import LandingPage from './landing/LandingPage';
import { Routes, Route } from 'react-router';
import { useDispatch, useSelector } from 'react-redux'
import Home from './Home';
import SearchResults from './results/SearchResults';
import FilterResults from './results/FilterResults';
import { checkAuthenticated, load_user } from '../actions/AuthActions';
import UserLayout from './UserLayout'


function UserContent() {

  const dispatch = useDispatch()

  const [video, setVideo] = useState()

  const myPlaylistsRef = useRef()

  useEffect(()=>{
    dispatch(checkAuthenticated())
    dispatch(load_user())
  },[])

  return (
    <>
      <Routes>
        <Route exact path = '/' element = {
          <LandingPage
          /> }
        />
        <Route element = {<UserLayout myPlaylistsRef = {myPlaylistsRef} />}>
            <Route path = '/home' element = {
                <Home
                    video = {video}
                    setVideo={setVideo}
                    myPlaylistsRef = {myPlaylistsRef}
                  />
                }/>
            <Route path = '/search_results/:keyword/' element = {
                <SearchResults
                    setVideo = {setVideo}
                />
                }             
            />
            <Route path = '/filter_results/:filterList' element = {
                <FilterResults
                    setVideo = {setVideo}
                />
                }             
            />
        </Route>
        {/* <Route path="*"  element = {
          <Navigate to="/" />
        }/> */}
      </Routes>
    </>
  )
}

export default UserContent;