import React, {useState, useEffect, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddVideoFilterComponent from './AddVideoFilterComponent';
import { getMoodsForAdmin } from '../../actions/AdminFilterActions';


const AdminMoods = (props) => {

    const searchAdminMoodRef = useRef()

    const dispatch = useDispatch()

    const [moodActive, setMoodActive] = useState(false)

    const [moodValue, setMoodValue] = useState("")


    useEffect(()=>{
        dispatch(getMoodsForAdmin())   
    },[])

    const {adminMoodsLoading, adminMoods} = useSelector(state => state.adminMoods)

    useEffect(()=>{
        document.addEventListener("mousedown", (event) => {
            if (searchAdminMoodRef == null || searchAdminMoodRef.current == null || !searchAdminMoodRef.current.contains(event.target)){
            setMoodActive(false)
            }
        })
    }) 

    const chooseHandler = (mood) => {
        if(!props.moods.includes(mood)){
            props.setMoods(prev=>[...prev, mood])
            props.setChanges(true)
        }
        setMoodActive(false)
        setMoodValue("")
    }

    const handleScroll = () => {

    }

    return(
        <>
            <div className = "text-secondary fw-bold pb-2 pt-4 w-100">Moods*</div>
            <div className='d-flex w-100 justify-content-between align-items-end'>
                <AddVideoFilterComponent
                    data = {adminMoods}
                    setValue = {setMoodValue}
                    setActive = {setMoodActive}
                    value = {moodValue}
                    isActive = {moodActive}
                    chooseHandler = {chooseHandler}
                    componentRef = {searchAdminMoodRef}
                    handleScroll = {handleScroll}
                    loading = {adminMoodsLoading}
                    chosenData = {props.moods}
                    setChosenData = {props.setMoods}
                    width = "100%"
                />
            </div>
        </>
    )

};

export default AdminMoods;