import {

    WATERMARK_REQUEST,
    WATERMARK_SUCCESS,
    WATERMARK_FAIL,

    FOOTPRINT_REQUEST,
    FOOTPRINT_SUCCESS,
    FOOTPRINT_FAIL,

    ANALYZE_INFO_REQUEST,
    ANALYZE_INFO_SUCCESS,
    ANALYZE_INFO_FAIL,

} from '../constants/AnalyzeConstants'


const initialState = {
  analyze_info: {},
  watermark_info: {},
  footprint_info: {},
  end_watermark: true,
  end_footprint: true,
}

  export const analyzeInfoReducer = (state = initialState, action) => {
    switch (action.type){
      case ANALYZE_INFO_REQUEST:
        return { 
          ...state,
          end_watermark: false,
          end_footprint: false,
          watermark_info: {},
          footprint_info: {}
        }
  
      case ANALYZE_INFO_SUCCESS:
        return { 
          ...state,
          analyze_info: action.payload 
        }
  
      case ANALYZE_INFO_FAIL:
        return { 
          ...state,
          end_watermark: true,
          end_footprint: true,
          error: action.payload
        }

      case WATERMARK_REQUEST:
        return { 
          watermark_info: {},
          ...state,
         }
  
      case WATERMARK_SUCCESS:
        return {
          ...state,
          watermark_info: action.watermark_info,
          end_watermark: true
        }
  
      case WATERMARK_FAIL:
        return { 
          ...state, 
          error: action.payload,
          end_watermark: true
        }

      case FOOTPRINT_REQUEST:
        return {
           ...state,
           footprint_info: {}
         }
  
      case FOOTPRINT_SUCCESS:
        return { 
          ...state,
          footprint_info: action.footprint_info,
          end_footprint: true,
        }
  
      case FOOTPRINT_FAIL:
        return { 
          ...state, 
          error: action.payload,
          end_footprint: true
        }

      default:
        return {...state
        }
      }
  }
